import styled, { css, AnyStyledComponent } from 'styled-components'
import { Typography } from '@material-ui/core'

export const Header = styled.header`
  ${({ theme }) => css`
    height: 100px;
    background-color: ${theme.palette.primary.main};
    display: flex;
    align-items: center;

    > .MuiGrid-container {
      width: 90%;
      margin: 0 auto;
    }
  `}
`

export const LogoutLink: AnyStyledComponent = styled(Typography)`
  color: white;
  cursor: pointer;
  font-weight: 800;
  text-decoration: none;
  display: inline-block;
  margin-left: 30px;
`
