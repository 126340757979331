import styled, { css, AnyStyledComponent } from 'styled-components'

export const TableRow: AnyStyledComponent = styled.tr`
  ${({ viewable }: any) => css`
    display: table-row;
    min-height: 50px;
    background-color: white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 8px 0;
    overflow: hidden;

    ${viewable &&
      css`
        cursor: pointer;
      `}
  `}
`

export const Spacer = styled.tr`
  height: 16px;
`

export const Column = styled.td`
  ${({ theme }) => css`
    padding: 10px;
    text-align: left;
    color: ${theme.palette.primary.main};
    font-weight: 700;

    svg {
      fill: ${theme.palette.primary.main};
    }

    .Mui-checked svg {
      fill: ${theme.palette.secondary.main};
    }
  `}
`

export const Actions = styled.td`
  height: 100%;
`

export const ActionsInner = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 100%;
`

export const ViewAction = styled.div`
  ${({ theme }) => css`
    width: 50px;
    height: 100%;
    color: ${theme.palette.primary.main};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `}
`

export const DeleteAction: any = styled.div`
  ${({ theme, deleting }: any) => css`
    width: 50px;
    height: 100%;
    flex: 1;
    color: ${theme.palette.primary.main};
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    ${deleting &&
      css`
        opacity: 0.5;
      `}
  `}
`
