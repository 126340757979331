/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css, AnyStyledComponent } from 'styled-components'

export const LocationsTree = styled.ul`
  ${({ theme }) => css`
    border-left: 3px solid ${theme.palette.primary.main};
    flex: 1;
    margin: 0;
    padding: 30px 0 100px 0;
    list-style: none;
    overflow: auto;
    position: relative;

    > p {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 1em;
    }

    ul {
      margin: 0 0 0 -30px;
      padding: 0;
      list-style: none;

      ul {
        margin-left: -16px;
      }
    }

    // First level indicator
    > li:after {
      content: '\\e0af';
      font-family: 'Material Icons';
      font-size: 20px;
      text-align: right;
      padding-right: 6px;
      line-height: 30px;
      color: white;
      position: absolute;
      top: 20px;
      left: 14px;
      width: 32px;
      height: 32px;
      border-radius: 32px;
    }

    // First level add indicator
    > li:last-child:after {
      content: '\\e145';
      font-family: 'Material Icons';
      text-align: right;
      width: 23px;
      height: 23px;
      top: 27px;
      left: 18px;
      line-height: 23px;
      font-size: 13px;
      font-weight: 700;
      padding-right: 5.4px;
    }

    // Second level indicator
    > li li:after {
      content: '';
      position: absolute;
      top: 27px;
      left: 25px;
      width: 16px;
      height: 16px;
      background-color: ${theme.palette.primary.main};
      border-radius: 20px;
    }

    // Second level add indicator
    > li li:last-child:after {
      content: '\\e145';
      color: white;
      font-family: 'Material Icons';
      text-align: right;
      padding-right: 4.9px;
      width: 23px;
      height: 23px;
      top: 23px;
      left: 23px;
      line-height: 23px;
      font-size: 13px;
      font-weight: 700;
    }

    > li {
      font-size: 21px;
      font-weight: 700;
    }

    > li li {
      font-size: 18px;
      font-weight: 600;
    }

    > li li li {
      font-size: 15px;
      font-weight: 400;
      padding-right: 60px;

      &:last-of-type {
        padding-right: 0;
      }
    }
  `}
`

export const Clickable: AnyStyledComponent = styled.div`
  ${({ level }: any) => css`
    position: absolute;
    top: ${level === 1 ? 14 : 18}px;
    left: ${level === 1 ? 8 : 16}px;
    bottom: ${level === 1 ? 'auto' : level === 2 ? 'initial' : '0'};
    right: ${level === 1 ? '0' : level === 2 ? '-20px' : '-50px'};
    height: ${level === 1 ? '44px' : level === 2 ? '34px' : 'initial'};
    min-height: 32px;
    z-index: -1;
    cursor: pointer;
    opacity: 0;
    pointer-events: all;
    display: flex;
    justify-content: flex-end;

    &:hover {
      opacity: 1;
    }
  `}
`

export const Li: AnyStyledComponent = styled.li`
  ${({ theme, active }: any) => css`
    position: relative;
    margin: -5px 0 0 -1px;
    padding: 5px 20px 0 60px;
    color: ${active ? theme.palette.secondary.main : theme.palette.primary.main};
    border-left: 1px solid ${theme.palette.primary.main};
    z-index: 1;
    pointer-events: none;
    text-indent: -60px;
    overflow-wrap: anywhere;

    &:last-child {
      border-left: none;
      font-size: 15px;
      font-weight: 400;

      &:before {
        border-left: 1px solid ${theme.palette.primary.main};
      }
    }

    &:before {
      position: relative;
      height: 35px;
      width: 20px;
      color: white;
      border-bottom: 1px solid ${theme.palette.primary.main};
      content: ' ';
      display: inline-block;
      left: 0px;
      margin-bottom: 4px;
      margin-right: 40px;
    }

    &:after {
      background-color: ${active
        ? theme.palette.secondary.main
        : theme.palette.primary.main} !important;
    }

    li {
      padding: 0 30px 0 50px;
      text-indent: -70px;

      &:before {
        width: 30px;
        left: 20px;
      }

      li:before {
        width: 40px;
      }

      li {
        padding: 0 30px 0 60px;
        text-indent: -80px;
      }
    }
  `}
`
