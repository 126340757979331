import styled, { css } from 'styled-components'

export const TableHeader = styled.tr`
  ${({ theme }) => css`
    padding: ${theme.spacing(2)}px;
    height: 50px;
    width: 100%;
  `}
`

export const Column = styled.td`
  ${({ theme }) => css`
    color: ${theme.palette.text.primary};
    font-family: ${theme.typography.fontFamily};
    font-weight: 600;
    padding: 0 10px;
    text-align: left;
    opacity: 0.7;
  `}
`

export const FakeColumn = styled.td`
  width: 50px;
  height: 50px;
`

export const Actions = styled.td`
  width: 100px;
`
