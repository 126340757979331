import React, { useState } from 'react'
import { Dispatch } from 'redux'
import { useDispatch } from 'react-redux'
import { useFormik, FormikHelpers } from 'formik'
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { NTABService } from '@Services'
import { IHttp } from '@Interfaces'
import { ResourcesActions } from '@Redux/actions'
import { Tabs } from '@Components/organisms'

interface IProps {
  open: boolean
  projectId: number
  onClose: () => void
}

export const CreateCategoryDialog = ({ open, onClose, projectId }: IProps) => {
  const dispatch: Dispatch = useDispatch()
  const [error, setError] = useState<string>('')

  const formik = useFormik({
    initialValues: {
      name: '',
      name_EN: '',
      projectId,
    },
    validate: (values: any) => {
      const errors: { [key: string]: string } = {}

      if (!values.name) {
        errors.name = 'Een naam is verplicht.'
      }

      return errors
    },
    onSubmit: async (values: any, { setSubmitting, resetForm }: FormikHelpers<any>) => {
      const result: IHttp.Response = await NTABService.CreateCategory(
        values.projectId,
        values.name,
        values.name_EN,
      )

      if (result.success) {
        dispatch(ResourcesActions.ReadResourceStart('categories', projectId))
        resetForm()
        onClose()
      } else {
        setError(result.error || 'Er ging iets fout, probeer het opnieuw.')
      }

      setSubmitting(false)
    },
  })

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Categorie aanmaken</DialogTitle>
        <DialogContent>
          {error && (
            <Box mb="20px">
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
          <Tabs
            tabs={[
              {
                label: 'Nederlands',
                id: 'create-category-tab-0',
              },
              {
                label: 'Engels',
                id: 'create-category-tab-1',
              },
            ]}
            panels={[
              {
                id: 'create-category-panel-0',
                component: () => ( // eslint-disable-line
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Nederlandse naam"
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={!!formik.touched.name && !!formik.errors.name}
                    helperText={formik.touched.name && formik.errors.name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                ),
              },
              {
                id: 'create-category-panel-1',
                component: () => ( // eslint-disable-line
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Engelse naam"
                    type="text"
                    name="name_EN"
                    value={formik.values.name_EN}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                ),
              },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose} disabled={formik.isSubmitting}>
            Annuleren
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={formik.isSubmitting}
            endIcon={formik.isSubmitting ? <CircularProgress size="20px" color="inherit" /> : null}
          >
            Opslaan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
