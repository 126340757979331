import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Paper } from '@material-ui/core'
import { DragDropContext } from 'react-beautiful-dnd'

import { IHttp, NTAB } from '@Interfaces'
import { NTABService } from '@Services'
import { ResourcesActions } from '@Redux/actions'
import { reorder } from '../../../../pages/projects/[projectId]/locations'
import { DroppableLocation } from './DroppableLocation'

interface IProps {
  projectId: number
  locations: NTAB.Location[]
}
export const SortLocations = ({ projectId, locations }: IProps) => {
  const dispatch = useDispatch()
  const [children, setChildren] = useState(locations || [])

  const handleOnDragEnd = async (result: any) => {
    if (result.source && result.destination) {
      const newChildren = reorder(children, result.source.index, result.destination.index)
      setChildren(newChildren)

      const requestResult: IHttp.Response = await NTABService.ReorderLocations(
        projectId,
        null,
        newChildren.map((c: NTAB.Location) => c.id),
      )

      if (requestResult.success === false) {
        alert('Er is iets fout gegaan. Probeer het opnieuw.')
      } else {
        dispatch(ResourcesActions.ReadResourceStart('locations', projectId))
      }
    }
  }

  return (
    <Paper>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {children.map((location: NTAB.Location, index: number) => (
          <Box key={index}>
            <DroppableLocation
              projectId={projectId}
              location={location}
              index={index}
              indentLevel={0}
            />
          </Box>
        ))}
      </DragDropContext>
    </Paper>
  )
}
