import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { NTABService } from '@Services'
import { NTAB, IHttp } from '@Interfaces'
import { DragIcon } from '@Components/atoms'
import { ResourcesActions } from '@Redux/actions'
import { DraggableLocation } from './DraggableLocation'
import { reorder } from '../../../../pages/projects/[projectId]/locations'
import * as Styled from './styled'

interface LocationInfoProps {
  projectId: number
  location: NTAB.Location
  indentLevel: number
  provided?: any
}

export const LocationInfo: React.FC<LocationInfoProps> = ({
  projectId,
  location,
  indentLevel,
  provided,
}: LocationInfoProps) => {
  const dispatch = useDispatch()
  const [children, setChildren] = useState(location.childs || [])

  const handleOnDragEnd = async (result: any) => {
    if (result.source && result.destination) {
      const newChildren = reorder(children, result.source.index, result.destination.index)
      setChildren(newChildren)

      const requestResult: IHttp.Response = await NTABService.ReorderLocations(
        projectId,
        location.id,
        newChildren.map((c: NTAB.Location) => c.id),
      )

      if (requestResult.success === false) {
        alert('Er is iets fout gegaan. Probeer het opnieuw.')
      } else {
        dispatch(ResourcesActions.ReadResourceStart('locations', projectId))
      }
    }
  }

  return (
    <Styled.Draggable
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...(indentLevel === 0 ? provided.dragHandleProps : {})}
    >
      <Styled.LocationName>
        <div {...provided.dragHandleProps}>
          <DragIcon />
        </div>
        <Typography variant="body1">{location.name}</Typography>
      </Styled.LocationName>
      {children.length > 0 ? (
        <Box style={{ paddingLeft: 30 * (indentLevel + 1) }}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId={`sort_location_droppable_${location.id}`}>
              {(provided: any) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {children.map((location: NTAB.Location, index: number) => (
                    <DraggableLocation
                      key={index}
                      projectId={projectId}
                      location={location}
                      index={index}
                      indentLevel={indentLevel + 1}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      ) : null}
    </Styled.Draggable>
  )
}

LocationInfo.defaultProps = {
  provided: {
    draggableProps: {},
    dragHandleProps: {},
  },
}
