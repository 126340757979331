import React from 'react'
import { Draggable } from 'react-beautiful-dnd'

import { NTAB } from '@Interfaces'
import { LocationInfo } from './LocationInfo'

interface DraggableLocationProps {
  projectId: number
  location: NTAB.Location
  index: number
  indentLevel: number
}

export const DraggableLocation: React.FC<DraggableLocationProps> = ({
  projectId,
  location,
  index,
  indentLevel,
}: DraggableLocationProps) => {
  return (
    <Draggable
      key={location.id}
      draggableId={`sort_location_draggable_${location.id}`}
      index={index}
    >
      {(provided: any) => (
        <LocationInfo
          projectId={projectId}
          provided={provided}
          location={location}
          indentLevel={indentLevel}
        />
      )}
    </Draggable>
  )
}
