import React from 'react'
import Head from 'next/head'
import { Grid } from '@material-ui/core'

import { Location } from '@Services'
import { HeaderComponent, IRuntimeStore } from '@Interfaces'
import { Header, Sidebar } from '@Components/organisms'
import { AuthTemplate } from '@Components/templates'
import * as Styled from './styled'

interface IProps {
  pageTitle: string
  children: any // eslint-disable-line
  headerItems: HeaderComponent.Item[]
  project: IRuntimeStore.CurrentProject
  locationId?: number
  locations?: Location[]
  showProjectCard?: boolean
  bottomPadding?: boolean
  logout: () => void
}

const _SidebarTemplate: React.FC<IProps> = ({
  pageTitle,
  children,
  headerItems,
  showProjectCard,
  project,
  locations,
  locationId,
  logout,
  bottomPadding,
}: IProps) => {
  return (
    <Styled.SidebarTemplate>
      <Head>
        <title>{pageTitle} | NTAB Portal</title>
      </Head>
      <Sidebar
        project={project}
        locations={locations}
        locationId={locationId}
        showProjectCard={showProjectCard}
      />
      <Styled.Main>
        <Header
          noLogo
          projectId={project.data ? project.data.id : 0}
          items={headerItems}
          logout={logout}
        />
        <Styled.Content bottomPadding={bottomPadding}>
          <Grid container>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Styled.Content>
      </Styled.Main>
    </Styled.SidebarTemplate>
  )
}

export const SidebarTemplate: React.FC<IProps> = (props: IProps) => {
  return (
    <AuthTemplate>
      <_SidebarTemplate {...props} />
    </AuthTemplate>
  )
}
