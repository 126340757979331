import React from 'react'

import { LoginTemplate, AuthTemplate } from '@Components/templates'

export const HomeTemplate: React.FC = () => {
  return (
    <AuthTemplate>
      <LoginTemplate />
    </AuthTemplate>
  )
}
