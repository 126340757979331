import React from 'react'

import { TableComponent } from '@Interfaces'
import * as Styled from './styled'

interface IProps {
  columns: TableComponent.Column[]
  selectable?: boolean
}

export const TableHeader: React.FC<IProps> = ({ columns, selectable }: IProps) => {
  return (
    <Styled.TableHeader>
      {selectable && <Styled.FakeColumn />}
      {columns.map((column: TableComponent.Column, i: number) => (
        <Styled.Column key={i}>{column.displayName}</Styled.Column>
      ))}
      <Styled.Actions />
    </Styled.TableHeader>
  )
}
