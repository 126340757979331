import React, { useState } from 'react'
import moment from 'moment'
import { Box, CircularProgress, Divider } from '@material-ui/core'

import { Location, ValuationType } from '@Services'
import { IRuntimeStore } from '@Interfaces'
import { Logo } from '@Components/atoms'
import { ProjectCard, SidebarItem } from '@Components/molecules'
import * as Styled from './styled'
import { CreateValuationTypeDialog } from '../AddValuationTypeDialog'

interface IProps {
  project: IRuntimeStore.CurrentProject
  locations?: Location[]
  showProjectCard?: boolean
  locationId?: number
}

export const Sidebar: React.FC<IProps> = ({
  project,
  locations,
  locationId,
  showProjectCard,
}: IProps) => {

  const [showAddValuationTypeDialog, setShowAddValuationTypeDialog] = useState<boolean>(false)
  
  const toggleAddValuationTypeDialog = () => {
    setShowAddValuationTypeDialog(!showAddValuationTypeDialog)
  }

  const handleAddValuationType = () => {
    toggleAddValuationTypeDialog()
  }

  return (
    <Styled.Sidebar>
      <Logo />
      {project.loading && (
        <Box padding="32px" textAlign="center">
          <CircularProgress />
        </Box>
      )}
      {project.data && (
        <>
          <Box
            my="20px"
            display={showProjectCard ? 'flex' : 'block'}
            flexDirection="column"
            height={showProjectCard ? '100%' : 'auto'}
          >
            <ProjectCard
              locations={locations}
              project={project.data}
              open={!!showProjectCard}
              locationId={locationId}
            />
          </Box>
          {!showProjectCard && (
            <>
              <SidebarItem name="Adres:" lines={[project.data.address, project.data.zipcode]} />
              <SidebarItem
                name="Taxatiedatum:"
                lines={[
                  moment(project.data.valuationDate, 'DD-MM-YYYY')
                    .format('DD MMM YYYY')
                    .toLowerCase(),
                ]}
              />
              <Divider />
              <Box mb="20px" />
              <SidebarItem name="Opdrachtgever" lines={[project.data.clientName]} />
              <SidebarItem name="Contact persoon" lines={[project.data.clientContact]} />
              <SidebarItem
                name="Adres"
                lines={[project.data.clientAddress, project.data.clientZipcode]}
              />
              <Divider />
              <Box mb="20px" />
              <Styled.Li>
                <Styled.Clickable level={1} onClick={handleAddValuationType} />
              </Styled.Li>
              <CreateValuationTypeDialog
                open={showAddValuationTypeDialog}
                onClose={toggleAddValuationTypeDialog}
                projectId={project.data.id}
                valuationTypes={project.data.valuationTypes}
              />
              <SidebarItem
                indicated
                name="Waardering"
                lines={project.data.valuationTypes.map((v: ValuationType) => v.name)}
              />
              <Divider />
            </>
          )}
        </>
      )}
    </Styled.Sidebar>
  )
}

Sidebar.defaultProps = {
  showProjectCard: false,
}
