import React from 'react'
import { Typography } from '@material-ui/core'

import { NTAB } from '@Interfaces'
import { LocationsTree } from '@Components/organisms'
import * as Styled from './styled'

interface IProps {
  open: boolean
  project: NTAB.Project
  locationId?: number
  locations?: NTAB.Location[]
}

export const ProjectCard: React.FC<IProps> = ({ open, project, locations, locationId }: IProps) => {
  return (
    <>
      <Styled.ProjectCard>
        <Typography variant="body1">
          <strong>{project.name}</strong>
        </Typography>
        <Typography variant="body1">{project.projectNumber}</Typography>
      </Styled.ProjectCard>
      {open && <LocationsTree project={project} locations={locations} locationId={locationId} />}
    </>
  )
}
