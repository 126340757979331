import React, { useState } from 'react'
import { Box, Checkbox, Dialog, FormControlLabel, IconButton, Typography } from '@material-ui/core'

import { DeleteIcon, EditIcon } from '@Components/atoms'
import { EditPhotoDialog } from '@Components/organisms'
import { NTABService, Photo } from '@Services'
import { IHttp } from '@Interfaces'
import * as Styled from './styled'

interface IProps {
  photo: Photo
  onEdit: (values: any, photo: Photo) => any
  onDelete: (photo: Photo) => any
  projectId?: number
}

export const PhotoCard: React.FC<IProps> = ({ projectId, photo, onEdit, onDelete }: IProps) => {

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [exportPhoto, setExportPhoto] = useState<boolean>(photo.exportToReport)
  const [showPhotoBox, setShowPhotoBox] = useState<boolean>(false)

  const handleOnEditClick = () => {
    setIsEditing(true)
  }

  const toggleEditing = () => {
    setIsEditing(!isEditing)
  }

  const togglePhotoBox = () => {
    setShowPhotoBox(!showPhotoBox)
  }

  const handleExportChange = async (e: any) => {
    setExportPhoto(!exportPhoto)

    const mustExport = e.target.checked
    let response: IHttp.Response

    if (photo.itemId) {
      response = await NTABService.UpdateItemPhoto(
        projectId || -1,
        photo.itemId,
        photo.id,
        photo.description,
        photo.descriptionEN,
        mustExport,
      )
    } else {
      response = await NTABService.UpdateProjectPhoto(
        projectId || -1,
        photo.id,
        photo.description,
        photo.descriptionEN,
        mustExport,
      )
    }

    if (!response.success) {
      alert(response.error)
    }
  }

  return (
    <>
      <Styled.PhotoCard elevation={3}>
        <Styled.Image
          src={photo.urlThumb || `data:image/png;base64,${photo.buffer}`}
          onClick={togglePhotoBox}
        />
        <Styled.Details>
          <Styled.Name>
            <Typography gutterBottom variant="body1">
              <strong>{photo.description || photo.itemDescription}</strong>
              &nbsp;
            </Typography>
            <IconButton onClick={handleOnEditClick} size="small">
              <EditIcon />
            </IconButton>
          </Styled.Name>
          <Styled.Detail>
            <Typography gutterBottom variant="caption">
              {photo.itemCategory || <>&nbsp;</>}
            </Typography>
          </Styled.Detail>
          <Styled.Detail>
            <Typography gutterBottom variant="body2">
              {!photo.itemId ? 'Algemene project foto' : photo.itemDescription || <>&nbsp;</>}
            </Typography>
          </Styled.Detail>
          {projectId && (
            <Box display="flex" alignItems="center" px="10px">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="bla"
                    checked={exportPhoto}
                    onChange={handleExportChange}
                  />
                }
                label="Exporteren"
              />
            </Box>
          )}
          <Box
            display="flex"
            alignItems="center"
            onClick={() => onDelete(photo)}
            style={{ cursor: 'pointer' }}
          >
            <Box
              height="42px"
              width="42px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Box>
            &nbsp;
            <Typography variant="body2">Verwijderen</Typography>
          </Box>
        </Styled.Details>
      </Styled.PhotoCard>
      <EditPhotoDialog
        photo={photo}
        open={isEditing}
        onClose={toggleEditing}
        onSubmit={(values: any) => onEdit(values, photo)}
      />
      <Dialog open={showPhotoBox} onClose={togglePhotoBox} maxWidth="lg" fullWidth>
        <img src={photo.urlOriginal} width="100%" />
      </Dialog>
    </>
  )
}
