import React from 'react'
import Head from 'next/head'

import { Header, Projects } from '@Components/organisms'
import { AuthTemplate } from '@Components/templates'
import { NTAB, TableComponent } from '@Interfaces'
import * as Styled from './styled'

interface IProps {
  pageTitle: string
  projects: NTAB.ProjectsItem[]
  onView: (data: TableComponent.RowData) => void
  onDelete?: (data: TableComponent.RowData) => void
  logout?: () => void
}

const _ProjectsTemplate: React.FC<IProps> = ({
  pageTitle,
  projects,
  onView,
  onDelete,
  logout,
}: IProps) => {
  return (
    <Styled.ProjectsTemplate>
      <Head>
        <title>{pageTitle} | NTAB Portal</title>
      </Head>
      <Header logout={logout} />
      <Projects projects={projects} onView={onView} onDelete={onDelete} />
    </Styled.ProjectsTemplate>
  )
}

export const ProjectsTemplate: React.FC<IProps> = (props: IProps) => {
  return (
    <AuthTemplate>
      <_ProjectsTemplate {...props} />
    </AuthTemplate>
  )
}
