import React, { useState, ChangeEvent } from 'react'

import { TabsComponent } from '@Interfaces'
import * as Styled from './styled'

interface IProps {
  tabs: TabsComponent.Tab[]
  panels: TabsComponent.Panel[]
  onChange?: (id: string) => void
}

export const Tabs: React.FC<IProps> = ({ tabs, panels, onChange }: IProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleTabChange = (_: ChangeEvent<{}>, newIndex: number) => {
    setCurrentIndex(newIndex)

    if (onChange) {
      onChange(tabs[newIndex].id)
    }
  }

  return (
    <>
      <Styled.Tabs onChange={handleTabChange} value={currentIndex}>
        {tabs.map((tab: TabsComponent.Tab, index: number) => (
          <Styled.Tab key={index} {...tab} />
        ))}
      </Styled.Tabs>
      {panels.map((panel: TabsComponent.Panel, index: number) => (
        <Styled.Panel
          key={index}
          value={currentIndex}
          index={index}
          role="panel"
          hidden={currentIndex !== index}
          {...panel}
        >
          {panel.component()}
        </Styled.Panel>
      ))}
    </>
  )
}
