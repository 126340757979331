import styled, { AnyStyledComponent, css } from 'styled-components'

export const SidebarTemplate = styled.div`
  display: flex;
`

export const Aside = styled.aside`
  background-color: white;
  height: 100vh;
  width: 332px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
`

export const Main = styled.main`
  height: 100vh;
  position: relative;
  flex: 1;
`

export const Content: AnyStyledComponent = styled.div`
  ${({ bottomPadding }: any) => css`
    padding: 48px 0;
    box-sizing: border-box;
    max-height: calc(100vh - 100px);
    overflow: auto;

    ${bottomPadding
      ? css`
          padding-bottom: 148px;
        `
      : ''}

    > .MuiGrid-container {
      width: 90%;
      margin: 0 auto;
    }
  `}
`
