import styled, { css, AnyStyledComponent } from 'styled-components'
import { Icon as MuiIcon, Typography } from '@material-ui/core'

export const HeaderItem: AnyStyledComponent = styled.div`
  ${({ theme, isActive }: any) => css`
    display: inline-flex;
    align-items: center;
    height: 100px;
    ${isActive && `background-color: ${theme.palette.primary.dark};`}

    &:hover {
      background-color: ${theme.palette.primary.dark};
    }
  `}
`

export const Link = styled.a`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`

export const Title: AnyStyledComponent = styled(Typography)`
  height: 100px;
  display: flex;
  align-items: center;
  color: white;
  font-weight: 800;
  text-align: center;
  box-sizing: border-box;
  padding: 20px;
  text-decoration: none;
`

export const Icon = styled(MuiIcon)`
  color: white;
  font-size: 32px;
  padding-left: 20px;
  width: 50px;
`
