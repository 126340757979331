import styled, { css, AnyStyledComponent } from 'styled-components'

export const Div: AnyStyledComponent = styled.div`
  ${({ onClick }: any) =>
    onClick
      ? css`
          cursor: pointer;
        `
      : ''}

  table {
    border: solid 1px #ddeeee;
    border-collapse: collapse;
    border-spacing: 0;
    font: normal 13px Arial, sans-serif;
  }

  table thead th {
    background-color: #ddefef;
    border: solid 1px #ddeeee;
    color: #336b6b;
    padding: 10px;
    text-align: left;
    text-shadow: 1px 1px 1px #fff;
  }

  table tbody td {
    border: solid 1px #ddeeee;
    color: #333;
    padding: 10px;
    text-shadow: 1px 1px 1px #fff;
  }
`
