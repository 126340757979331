import styled, { css, AnyStyledComponent } from 'styled-components'
import { Paper } from '@material-ui/core'

export const PhotoCard: AnyStyledComponent = styled(Paper)`
  ${({ theme }: any) => css`
    display: flex;
    width: 320px;
    flex-direction: column;
    overflow: hidden;
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;

    .MuiSvgIcon-root {
      fill: ${theme.palette.primary.main};
    }

    .MuiFormControlLabel-root {
      .MuiFormControlLabel-label {
        padding-left: 3px;
      }
    }
  `}
`

export const Image: AnyStyledComponent = styled.div`
  width: 320px;
  height: 150px;
  background-image: url('${({ src }: any) => src}');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
`

export const Details = styled.div`
  ${({ theme }) => css`
    height: 100%;
    flex: 1;
    color: ${theme.palette.primary.main};
    padding: 10px 20px;
    position: relative;
  `}
`

export const Name = styled.div`
  display: flex;
  align-items: center;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: elipsis;
    max-width: 90%;
  }
`

export const Detail = styled.div`
  display: flex;
  align-items: center;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: elipsis;
    max-width: 90%;
  }
`
