import React from 'react'
import Head from 'next/head'
import { Box, Button, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Logo } from '@Components/atoms'

import * as Styled from './styled'

interface IProps {
  login?: () => void
}

export const LoginTemplate: React.FC<IProps> = ({ login }: IProps) => {
  const { t } = useTranslation()

  return (
    <Styled.LoginTemplate>
      <Head>
        <title>Inloggen | NTAB Portal</title>
      </Head>
      <Styled.Half image="/images/NTAB-2016-taxateurs-klein-063.jpg" />
      <Styled.Half>
        <Styled.Content>
          <Logo size="large" />
          <Box mb="130px" />
          <Typography component="h1" variant="h4" gutterBottom>
            {t('Inloggen')}
          </Typography>
          <Box mb="16px" />
          <Button variant="contained" size="large" color="secondary" onClick={login}>
            Klik hier om in te loggen
          </Button>
        </Styled.Content>
      </Styled.Half>
    </Styled.LoginTemplate>
  )
}
