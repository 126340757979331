import styled, { css, AnyStyledComponent } from 'styled-components'
import { Tabs as MuiTabs, Tab as MuiTab } from '@material-ui/core'

export const Tabs = styled(MuiTabs)`
  .MuiTabs-fixed {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    width: auto;
  }

  .MuiTabs-indicator {
    display: none;
  }
`

export const Tab = styled(MuiTab)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-weight: 700;
    text-transform: none;
    background-color: #e5e5e5;

    &.Mui-selected {
      background-color: white;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
      z-index: 1;
    }
  `}
`

export const Panel: AnyStyledComponent = styled.div`
  background-color: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  padding: 32px;

  p {
    margin: 0px;
  }
`
