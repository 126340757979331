import React, { useEffect, useState } from 'react'
import findDeep from 'deepdash/findDeep'
import groupBy from 'lodash/groupBy'
import flatten from 'lodash/flatten'
import Router from 'next/router'
import { Dispatch } from 'redux'
import { NextPage } from 'next'
import Link from 'next/link'
import { useSelector, useDispatch } from 'react-redux'
import { END } from 'redux-saga'
import { Grid, Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { HeaderItems } from '@Definitions'
import { Empty } from '@Components/atoms'
import { Table, ArticleBulkDialog } from '@Components/organisms'
import { SidebarTemplate } from '@Components/templates'
import { IStore, IHttp, TableComponent } from '@Interfaces'
import { NTABService, Item } from '@Services'
import { getCurrentProject } from '@Redux/runtime/selectors'
import { getResourceItems, getResourceStatus } from '@Redux/resources/selectors'
import { RuntimeActions, ResourcesActions } from '@Redux/actions'
import { SagaStore } from '@Redux/store'

const itemColumns: TableComponent.Column[] = [
  {
    name: 'amount',
    displayName: 'Aantal',
  },
  {
    name: 'name',
    displayName: 'Omschrijving',
  },
  {
    name: 'brand',
    displayName: 'Merk',
  },
  {
    name: 'type',
    displayName: 'Type',
  },
  {
    name: 'categorie',
    displayName: 'Categorie',
  },
  {
    name: 'values',
    displayName: 'Waardering',
    // eslint-disable-next-line react/display-name
    map: ({ abbreviation, value }: any) => {
      return (
        <>
          {abbreviation} - {value}
          <br />
        </>
      )
    },
    empty: () => 'P.M.',
  },
]

// a little function to help us with reordering the result
export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

interface IProps {
  locationId: number
  projectId: number
  logout: () => void
}

const LocationsPage: React.FC<IProps> = ({ locationId, projectId, logout }: IProps) => {
  const dispatch: Dispatch = useDispatch()

  const currentProject = useSelector((state: IStore) => getCurrentProject(state.runtime))
  const locations = useSelector((state: IStore) => getResourceItems('locations', state.resources))
  const items = useSelector((state: IStore) => getResourceItems('items', state.resources))
  const isReadingItems = useSelector((state: IStore) =>
    getResourceStatus('items', 'isReading', state.resources),
  )
  const currentLocation = findDeep(locations, { id: locationId }, { childrenPath: 'childs' })

  const [rows, setRows] = useState<TableComponent.Row[]>([])

  const [showBulkDialog, setShowBulkDialog] = useState<boolean>(false)
  const [selected, setSelected] = useState<number[]>([])
  const [error, setError] = useState<string>('')
  const [bulkError, setBulkError] = useState<string>('')
  const [deletingItem, setDeletingItem] = useState<number>(-1)

  const getGroupedArticles = () => groupBy(rows, 'data.categorie') as any

  const onSelect = (e: any, item: Item) => {
    if (e.target.checked) {
      if (selected.indexOf(item.id) === -1) {
        setSelected([...selected, item.id])
      }
    } else {
      setSelected(selected.filter(s => s !== item.id))
    }
  }

  const onView = (article: Item) => {
    Router.push(
      '/projects/[projectId]/locations/[locationId]/articles/[articleId]',
      `/projects/${projectId}/locations/${locationId}/articles/${article.id}`,
    )
  }

  const onDelete = async (data: any) => {
    const id: number = data.id

    setDeletingItem(id)

    const result: IHttp.Response = await NTABService.DeleteItem(id, projectId)

    if (result.success) {
      dispatch(
        ResourcesActions.ReadResourceStart('items', projectId, 'extensive-overview', locationId),
      )
      setDeletingItem(-1)
    } else {
      setError(result.error || 'Er ging iets fout, probeer het opnieuw.')
    }
  }

  const toggleBulkDialog = () => {
    setShowBulkDialog(!showBulkDialog)
  }

  const handleBulkSubmit = async (values: any): Promise<boolean> => {
    values.items = selected

    if (!values.visualInspectionReserved) {
      values.visualInspectionReserved = null
    }

    setBulkError('')
    const result: IHttp.Response = await NTABService.BulkUpdateItems(values)

    if (!result.success) {
      setBulkError('Er zijn geen wijzigingen opgeslagen.')
    } else {
      dispatch(
        ResourcesActions.ReadResourceStart('items', projectId, 'extensive-overview', locationId),
      )
    }

    return result.success
  }

  const handleOnDragEnd = async (result: any) => {
    if (!result.destination) {
      return
    }

    const groupIndex = parseInt(result.source.droppableId.replace('droppableTable-', ''), 10)
    const groupedRows = getGroupedArticles()
    
    const groupedKeys = Object.keys(groupedRows)

    const oldGroupedRows = groupedRows[groupedKeys[groupIndex]]
    const newGroupedRows: TableComponent.Row[] = reorder(
      oldGroupedRows,
      result.source.index,
      result.destination.index,
    )

    groupedRows[groupedKeys[groupIndex]] = newGroupedRows

    const oldRows = rows
    const newRows: any[] = flatten(
      Object.keys(groupedRows).map((key: string) => {
        return groupedRows[key]
      }),
    )

    setRows(newRows)
    const itemIds = newRows.map((row: TableComponent.Row) => row.data.id)
    const sortResult: IHttp.Response = await NTABService.SortItems(projectId, locationId, itemIds)

    if (!sortResult.success) {
      setRows(oldRows)

      if (sortResult) {
        setError(sortResult.error || 'Er ging iets fout, probeer het opnieuw.')
      }
    } else {
      dispatch(
        ResourcesActions.ReadResourceStart('items', projectId, 'extensive-overview', locationId),
      )
    }
  }

  useEffect(() => {
    if (isReadingItems === false) {
      setRows(
        (items as any).map((item: Item) => ({
          data: item,
          onSelect,
          onView,
          onDelete,
        })),
      )
    }
  }, [items, isReadingItems, selected])

  useEffect(() => {
    setSelected([])
  }, [locationId])

  const renderContent = () => {
    if (rows.length === 0) {
      if (isReadingItems) {
        return (
          <Box textAlign="center" padding="32px">
            <CircularProgress />
          </Box>
        )
      }

      return <Empty text="Er zijn geen artikelen om te tonen." />
    }

    const tables: any[] = []
    const grouped = getGroupedArticles()

    Object.keys(grouped).forEach((categorie: string, i: number) => {
      tables.push(
        <Box mb="32px" key={`locations_page_table_${i}`}>
          <Typography gutterBottom variant="h5">
            <strong>{categorie}</strong>
          </Typography>
          <Table
            draggable
            selectable
            onDragEnd={handleOnDragEnd}
            columns={itemColumns}
            rows={grouped[categorie]}
            deletingId={deletingItem}
            selected={selected}
            tableIndex={i}
          />
        </Box>,
      )
    })

    return tables
  }

  return (
    <SidebarTemplate
      pageTitle="Locatie overzicht"
      headerItems={HeaderItems}
      project={currentProject}
      locationId={locationId}
      locations={locations}
      logout={logout}
      showProjectCard
    >
      <Link
        href="/projects/[projectId]/locations/sort"
        as={`/projects/${projectId}/locations/sort`}
      >
        <Button color="primary" size="large" variant="contained">
          Locaties sorteren
        </Button>
      </Link>
      <Box height="20px" />
      {locationId === 0 ? (
        <Typography variant="body1" color="primary">
          Er is geen locatie geselecteerd.
        </Typography>
      ) : (
        <>
          <Grid container>
            {currentLocation && currentLocation.value.level > 0 && (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end" mb="16px">
                  <Box mr="8px">
                    <Link
                      href="/projects/[projectId]/locations/[locationId]/articles"
                      as={`/projects/${projectId}/locations/${locationId}/articles`}
                    >
                      <Button color="secondary" size="large" variant="contained">
                        Artikel toevoegen
                      </Button>
                    </Link>
                  </Box>
                  {rows.length > 0 && (
                    <Button
                      color="primary"
                      size="large"
                      variant="contained"
                      onClick={toggleBulkDialog}
                    >
                      Bulk actie uitvoeren
                    </Button>
                  )}
                </Box>
              </Grid>
            )}
            {error && (
              <Grid item xs={12}>
                <Box mb="16px">
                  <Alert severity="error">{error}</Alert>
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              {renderContent()}
            </Grid>
          </Grid>
          <ArticleBulkDialog
            error={bulkError}
            projectId={projectId}
            selected={selected}
            open={showBulkDialog}
            onClose={toggleBulkDialog}
            onSubmit={handleBulkSubmit}
          />
        </>
      )}
    </SidebarTemplate>
  )
}
;(LocationsPage as NextPage).getInitialProps = async ({ query, store, req }) => {
  const state = store.getState()
  const projectId = parseInt(query.projectId as string, 10)

  const projectAlreadyFetched =
    projectId === (state.runtime.currentProject.data ? state.runtime.currentProject.data.id : null)

  if (!projectAlreadyFetched) {
    store.dispatch(RuntimeActions.SetCurrentProjectStart(projectId))
  }

  if (!state.resources.locations || !projectAlreadyFetched) {
    store.dispatch(ResourcesActions.ReadResourceStart('locations', projectId))
  }

  if (query.locationId) {
    store.dispatch(
      ResourcesActions.ReadResourceStart(
        'items',
        parseInt(query.projectId as string, 10),
        'extensive-overview',
        parseInt(query.locationId as string, 10),
      ),
    )

    store.dispatch(
      ResourcesActions.ReadResourceStart('categories', parseInt(query.projectId as string, 10)),
    )

    store.dispatch(ResourcesActions.ReadResourceStart('vat'))
  }

  if (req) {
    store.dispatch(END)
    await (store as SagaStore).sagaTask.toPromise()
  }

  return {
    locationId: parseInt((query.locationId as string) || '0', 10),
    projectId: parseInt((query.projectId as string) || '0', 10),
  }
}

export default LocationsPage
