import React, { useEffect, useState } from 'react'
import { useFormik, FormikHelpers } from 'formik'
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Tabs } from '@Components/organisms'
import { Photo } from '@Services'

import * as Styled from './styled'

interface IProps {
  photo: Photo
  open: boolean
  onClose: () => void
  onSubmit: (values: any) => Promise<void>
}

export const EditPhotoDialog = ({ photo, open, onClose, onSubmit }: IProps) => {
  const [error, setError] = useState<string>('')

  const formik = useFormik({
    initialValues: {
      description: photo.description,
      descriptionEN: photo.descriptionEN,
      exportToReport: photo.exportToReport,
    },
    onSubmit: async (values: any, { setSubmitting, resetForm }: FormikHelpers<any>) => {
      try {
        await onSubmit(values)
        resetForm()
        onClose()
      } catch (e) {
        setError(e.error)
      }

      setSubmitting(false)
    },
  })

  useEffect(() => {
    formik.setFieldValue('description', photo.description)
    formik.setFieldValue('descriptionEN', photo.descriptionEN)
    formik.setFieldValue('exportToReport', photo.exportToReport)
  }, [photo])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Fotobijlage</DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <Tabs
            tabs={[
              {
                label: 'Nederlandse omschrijving',
                id: 'edit-translated-content-dialog-tabs-tab-0',
              },
              {
                label: 'Engelse omschrijving',
                id: 'edit-translated-content-dialog-tabs-tab-1',
              },
            ]}
            panels={[
              {
                id: 'edit-translated-content-dialog-tabs-panel-0',
                component: () => ( // eslint-disable-line
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Nederlandse omschrijving"
                    type="text"
                    rows={4}
                    name="description"
                    multiline
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                ),
              },
              {
                id: 'edit-translated-content-dialog-tabs-panel-1',
                component: () => ( // eslint-disable-line
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Engelse vertaling"
                    type="text"
                    name="descriptionEN"
                    rows={4}
                    value={formik.values.descriptionEN}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                    fullWidth
                  />
                ),
              },
            ]}
          />
          <Styled.Box mt="32px">
            <FormControlLabel
              label="Afbeelding exporteren"
              control={
                <Checkbox
                  name="exportToReport"
                  checked={formik.values.exportToReport}
                  onChange={formik.handleChange}
                  color="primary"
                />
              }
            />
          </Styled.Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose} disabled={formik.isSubmitting}>
            Annuleren
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={formik.isSubmitting}
            endIcon={formik.isSubmitting ? <CircularProgress size="20px" color="inherit" /> : null}
          >
            Opslaan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
