import React, { useState } from 'react'
import Router from 'next/router'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { IconButton } from '@material-ui/core'

import { NTAB, IHttp } from '@Interfaces'
import { NTABService } from '@Services'
import { EditIcon } from '@Components/atoms'
import { AddLocationDialog, EditLocationDialog } from '@Components/organisms'
import { ResourcesActions } from '@Redux/actions'
import * as Styled from './styled'

interface IProps {
  project: NTAB.Project
  locationId?: number
  locations?: NTAB.Location[]
}

export const LocationsTree: React.FC<IProps> = ({ locationId, project, locations }: IProps) => {
  const dispatch: Dispatch = useDispatch()

  const [addingToParentId, setAddingToParentId] = useState<number | null>(null)
  const [editingLocation, setEditingLocation] = useState<NTAB.Location | null>(null)

  const [showAddLocationDialog, setShowAddLocationDialog] = useState<boolean>(false)
  const [showEditLocationDialog, setShowEditLocationDialog] = useState<boolean>(false)

  const [isUpdatingLocation, setIsUpdatingLocation] = useState<boolean>(false)
  const [isDeletingLocation, setIsDeletingLocation] = useState<boolean>(false)
  const [deleteError, setDeleteError] = useState<string>('')

  const toggleAddLocationDialog = () => {
    setShowAddLocationDialog(!showAddLocationDialog)
  }

  const toggleEditLocationDialog = (e?: React.MouseEvent, location?: NTAB.Location) => {
    if (e) {
      e.stopPropagation()
    }

    if (location) {
      setEditingLocation(location)
    }

    setShowEditLocationDialog(!showEditLocationDialog)
  }

  const handleOnView = (e: React.MouseEvent, location: NTAB.Location) => {
    e.stopPropagation()
    Router.push(
      '/projects/[projectId]/locations/[locationId]',
      `/projects/${project.id}/locations/${location.id}`,
    )
  }

  const handleAddLocation = (parentId?: number) => {
    setAddingToParentId(parentId || null)
    toggleAddLocationDialog()
  }

  const handleUpdateLocation = async (values: any) => {
    if (!editingLocation) return

    setIsUpdatingLocation(true)
    const result: IHttp.Response = await NTABService.UpdateLocation(
      project.id,
      editingLocation.id,
      values.name,
      values.nameEN,
    )

    if (result.error) {
      alert(result.error)
    } else {
      dispatch(ResourcesActions.ReadResourceStart('locations', project.id))
      toggleEditLocationDialog()
      setEditingLocation(null)
    }

    setIsUpdatingLocation(false)
  }

  const handleDeleteLocation = async () => {
    if (editingLocation === null) return

    const { id } = editingLocation
    setIsDeletingLocation(true)
    const result: IHttp.Response = await NTABService.DeleteLocation(id, project.id)

    if (result.error) {
      setDeleteError(
        'Deze locatie kan niet verwijderd worden omdat de locatie niet bestaat of omdat er nog artikelen aan gekoppeld zijn.',
      )
    } else {
      dispatch(ResourcesActions.ReadResourceStart('locations', project.id))
      toggleEditLocationDialog()
      setEditingLocation(null)
    }

    setIsDeletingLocation(false)
  }

  const handleSubmitLocation = async (values: any) => {
    const result: IHttp.Response = await NTABService.AddLocation(
      values.name,
      values.nameEN,
      addingToParentId || null,
      project.id,
    )

    if (result.error) {
      alert(result.error)
    } else {
      setAddingToParentId(null)
      dispatch(ResourcesActions.ReadResourceStart('locations', project.id))
    }
  }

  return (
    <>
      <Styled.LocationsTree>
        {locations &&
          locations.map((location: NTAB.Location, i: number) => (
            <Styled.Li key={i} active={locationId === location.id}>
              {location.name}
              {location.childs && location.childs.length > 0 ? (
                <ul>
                  {location.childs.map((child: NTAB.Location, j: number) => (
                    <Styled.Li key={j} active={locationId === child.id}>
                      {child.name}
                      {child.childs && child.childs.length > 0 ? (
                        <ul>
                          {child.childs.map((grandChild: NTAB.Location, k: number) => (
                            <Styled.Li key={k} active={locationId === grandChild.id}>
                              {grandChild.name}
                              <Styled.Clickable
                                level={3}
                                onClick={(e: React.MouseEvent) => handleOnView(e, grandChild)}
                              >
                                <IconButton
                                  onClick={(e: React.MouseEvent) =>
                                    toggleEditLocationDialog(e, grandChild)
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              </Styled.Clickable>
                            </Styled.Li>
                          ))}
                          <Styled.Li
                            style={{ display: locationId === child.id ? 'block' : 'none' }}
                          >
                            Voeg een sub-ruimte toe
                            <Styled.Clickable
                              level={3}
                              onClick={() => handleAddLocation(child.id)}
                            />
                          </Styled.Li>
                        </ul>
                      ) : (
                        <ul>
                          <Styled.Li
                            style={{ display: locationId === child.id ? 'block' : 'none' }}
                          >
                            Voeg een sub-ruimte toe
                            <Styled.Clickable
                              level={3}
                              onClick={() => handleAddLocation(child.id)}
                            />
                          </Styled.Li>
                        </ul>
                      )}
                      <Styled.Clickable
                        level={2}
                        onClick={(e: React.MouseEvent) => handleOnView(e, child)}
                        style={locationId === child.id ? { opacity: 1 } : undefined}
                      >
                        <IconButton
                          onClick={(e: React.MouseEvent) => toggleEditLocationDialog(e, child)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Styled.Clickable>
                    </Styled.Li>
                  ))}
                  <Styled.Li style={{ display: locationId === location.id ? 'block' : 'none' }}>
                    Voeg een ruimte toe
                    <Styled.Clickable
                      level={2}
                      onClick={() => handleAddLocation(location.id)}
                    />
                  </Styled.Li>
                </ul>
              ) : (
                <ul>
                  <Styled.Li style={{ display: locationId === location.id ? 'block' : 'none' }}>
                    Voeg een ruimte toe
                    <Styled.Clickable
                      level={2}
                      onClick={() => handleAddLocation(location.id)}
                    />
                  </Styled.Li>
                </ul>
              )}
              <Styled.Clickable
                level={1}
                onClick={(e: React.MouseEvent) => handleOnView(e, location)}
              >
                <IconButton
                  onClick={(e: React.MouseEvent) => toggleEditLocationDialog(e, location)}
                >
                  <EditIcon />
                </IconButton>
              </Styled.Clickable>
            </Styled.Li>
          ))}
        <Styled.Li>
          Voeg een locatie toe
          <Styled.Clickable level={1} onClick={() => handleAddLocation()} />
        </Styled.Li>
      </Styled.LocationsTree>
      <AddLocationDialog
        open={showAddLocationDialog}
        onClose={toggleAddLocationDialog}
        onSubmit={handleSubmitLocation}
      />
      <EditLocationDialog
        open={showEditLocationDialog}
        isDeleting={isDeletingLocation}
        isUpdating={isUpdatingLocation}
        location={editingLocation}
        onClose={() => toggleEditLocationDialog()}
        onUpdate={handleUpdateLocation}
        onDelete={handleDeleteLocation}
        deleteError={deleteError}
      />
    </>
  )
}
