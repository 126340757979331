import styled, { css } from 'styled-components'
import { Box as MuiBox } from '@material-ui/core'

export const Draggable = styled(MuiBox)`
  ${({ theme }: any) => css`
    background-color: ${theme.palette.background.default};
  `}
`

export const LocationName = styled(MuiBox)`
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #f1f1f1;
`
