import { IResourcesStore } from '@Interfaces'
import { INITIAL_STATE } from './reducer'

export const getResourceItems = (
  resource: string,
  state: IResourcesStore.InitialState = INITIAL_STATE,
): any[] => {
  const store = state[resource]

  if (store) {
    return store.items
  }

  return []
}

export const getResourceStatus = (
  resource: string,
  status: IResourcesStore.TStatus,
  state: IResourcesStore.InitialState = INITIAL_STATE,
): boolean | null => {
  const store = state[resource]

  if (!store) {
    return null
  }

  return store[status]
}

export const getResourceError = (
  resource: string,
  state: IResourcesStore.InitialState = INITIAL_STATE,
): string | null => {
  const store = state[resource]

  if (!store) {
    return null
  }

  return store.error
}
