import React from 'react'
import { Dispatch } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'

import { HeaderComponent, IStore } from '@Interfaces'
import { Logo } from '@Components/atoms'
import { HeaderItem } from '@Components/molecules'
import { RuntimeActions } from '@Redux/actions'
import { getCurrentUser } from '@Redux/runtime/selectors'

import * as Styled from './styled'

interface IProps {
  projectId?: number
  noLogo?: boolean
  items?: HeaderComponent.Item[]
  logout?: () => void
}

export const Header: React.FC<IProps> = ({ projectId, items, noLogo, logout }: IProps) => {
  const dispatch: Dispatch = useDispatch()
  const currentUser: any = useSelector((store: IStore) => getCurrentUser(store.runtime))

  const handleLogout = () => {
    dispatch(RuntimeActions.Logout())

    if (logout) {
      logout()
    }
  }

  const getItems = () => {
    if (!items) {
      return []
    }

    return items.slice(0, items.length - 1)
  }

  const renderLastItem = () => {
    if (currentUser && !currentUser.secretariat || !items) {
      return null
    }

    const item: any = items[(items as any).length - 1]

    return (
      // eslint-disable-next-line react/prop-types
      <HeaderItem {...item} as={projectId ? item.as.replace('{{id}}', `${projectId}`) : item.as} />
    )
  }

  return (
    <Styled.Header>
      <Grid container alignItems="center">
        {!noLogo && (
          <Grid item xs={2}>
            <Logo variant="light" />
          </Grid>
        )}
        <Grid item xs={noLogo ? 9 : 7}>
          {getItems().map((item: HeaderComponent.Item, i: number) => {
            return (
              <HeaderItem
                key={i}
                {...item}
                as={projectId ? item.as.replace('{{id}}', `${projectId}`) : item.as}
              />
            )
          })}
        </Grid>
        <Grid
          item
          xs={3}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
              >
          {renderLastItem()}
          {logout && <Styled.LogoutLink onClick={handleLogout}>Uitloggen</Styled.LogoutLink>}
        </Grid>
      </Grid>
    </Styled.Header>
  )
}
