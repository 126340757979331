import React from 'react'
import { useFormik } from 'formik'
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useSelector } from 'react-redux'

import { getResourceItems } from '@Redux/resources/selectors'
import { IStore } from '@Interfaces'
import { Location, Category, Vat } from '@Services'

interface IProps {
  error: string
  projectId: number
  open: boolean
  selected: number[]
  onClose: () => void
  onSubmit: (values: any) => Promise<boolean>
}

export const ArticleBulkDialog = ({
  error,
  projectId,
  open,
  selected,
  onClose,
  onSubmit,
}: IProps) => {
  const categories = useSelector((state: IStore) => getResourceItems('categories', state.resources))
  const locations = useSelector((state: IStore) => getResourceItems('locations', state.resources))
  const vats = useSelector((state: IStore) => getResourceItems('vat', state.resources))

  const formik = useFormik({
    initialValues: {
      projectId,
      locationId: -1,
      categoryId: -1,
      vatId: -1,
      visualInspectionReserved: false,
    },
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      const success: boolean = await onSubmit(values)

      if (success) {
        resetForm()
        onClose()
      }

      setSubmitting(false)
    },
  })

  const renderLocationOptions = (locations: Location[], indentLevel = 0) => {
    let menuItems: any[] = []
    const indent = []

    for (let i = 0; i < indentLevel; i++) {
      indent.push(<React.Fragment key={i}>&nbsp;&nbsp;</React.Fragment>)
    }

    for (let i = 0; i < locations.length; i++) {
      const location: Location = locations[i]

      menuItems.push(
        <MenuItem key={location.id} disabled={indentLevel === 0} value={location.id}>
          {indent}
          {location.name}
        </MenuItem>,
      )

      if (location.childs) {
        menuItems = [...menuItems, ...renderLocationOptions(location.childs, indentLevel + 1)]
      }
    }

    return menuItems
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Bulk actie uitvoeren</DialogTitle>
        <DialogContent>
          {error && (
            <Box mb="20px">
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
          {selected.length === 0 ? (
            <Typography variant="body1">U heeft geen artikelen geselecteerd.</Typography>
          ) : (
            <Grid container>
              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <strong>Categorie</strong>
                </Grid>
                <Grid item xs={3}>
                  <strong>Locatie</strong>
                </Grid>
                <Grid item xs={3}>
                  <strong>BTW</strong>
                </Grid>
                <Grid item xs={3}>
                  <strong>Voorbehoud Visuele inspectie</strong>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <Select
                    fullWidth
                    name="categoryId"
                    value={formik.values.categoryId}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={-1}>Maak een keuze</MenuItem>
                    {(categories as Category[]).map((category: Category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Select
                    fullWidth
                    name="locationId"
                    value={formik.values.locationId}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={-1}>Maak een keuze</MenuItem>
                    {renderLocationOptions(locations)}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Select
                    fullWidth
                    name="vatId"
                    value={formik.values.vatId}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={-1}>Maak een keuze</MenuItem>
                    {(vats as Vat[]).map((vat: Vat) => (
                      <MenuItem key={vat.id} value={vat.id}>
                        {vat.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Select
                    fullWidth
                    name="visualInspectionReserved"
                    value={formik.values.visualInspectionReserved}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={false as any}>Nee</MenuItem>
                    <MenuItem value={true as any}>Ja</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" disabled={formik.isSubmitting} onClick={onClose} size="large">
            Annuleren
          </Button>
          {selected.length > 0 && (
            <Button
              variant="contained"
              color="secondary"
              disabled={formik.isSubmitting}
              size="large"
              endIcon={
                formik.isSubmitting ? <CircularProgress size="20px" color="inherit" /> : null
              }
              type="submit"
            >
              Uitvoeren
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  )
}
