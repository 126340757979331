import React, { useState } from 'react'
import { useFormik, FormikHelpers } from 'formik'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  MenuItem,
  Select,
  Typography,
  CircularProgress,
  IconButton,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { DeleteIcon } from '@Components/atoms'
import { Tabs } from '@Components/organisms'

interface IProps {
  open: boolean
  onClose: () => void
  onSubmit: (values: any) => Promise<void>
}

export const ImportExportCsvDialog = ({ open, onClose, onSubmit }: IProps) => {
  const [error, setError] = useState<string>('')

  const formik = useFormik({
    initialValues: {
      tab: 'import',
      type: 'Unknown',
      buffer: '',
      mimeType: '',
      _filename: '',
    },
    validate: async (values: any) => {
      const errors: any = {}

      if (values.type === 'Unknown') {
        errors.type = 'Selecteer een optie.'
      }

      if (values.tab === 'import' && values._filename === '') {
        errors._filename = 'Selecteer een bestand.'
      }

      return errors
    },
    onSubmit: async (values: any, { setSubmitting, resetForm }: FormikHelpers<any>) => {
      setError('')

      try {
        await onSubmit(values)
        resetForm()
        onClose()
      } catch (e) {
        setError(e.message)
      }

      setSubmitting(false)
    },
  })

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files

    if (fileList && fileList.length > 0) {
      const file = fileList[0]
      formik.setFieldValue('_filename', file.name)
      formik.setFieldValue('mimeType', file.type)

      const reader = new FileReader()
      reader.addEventListener('load', (e: any) => {
        formik.setFieldValue('buffer', e.target.result)
      })
      reader.readAsDataURL(file)
    }
  }

  const handleTabChange = (tab: string) => {
    formik.resetForm()
    formik.setFieldValue('tab', tab)
    setError('')
  }

  const handleClearSelectedFile = () => {
    formik.setFieldValue('_filename', '')
    formik.setFieldValue('mimeType', '')
    formik.setFieldValue('mimeType', '')
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Importeer/exporteer CSV</DialogTitle>
        <DialogContent>
          <Tabs
            onChange={handleTabChange}
            tabs={[
              {
                label: 'Importeren',
                id: 'import',
              },
              {
                label: 'Exporteren',
                id: 'export',
              },
            ]}
            panels={[
              {
                id: 'import-tab',
                component: () => ( // eslint-disable-line
                  <>
                    {error && (
                      <Box>
                        <Alert severity="error">{error}</Alert>
                      </Box>
                    )}
                    <Box mt="16px">
                      <Box mb="8px">
                        <Button variant="contained" component="label">
                          {formik.values._filename ? formik.values._filename : 'Kies bestand'}
                          <input
                            type="file"
                            accept=".csv, .txt"
                            onChange={handleFileSelect}
                            style={{ display: 'none' }}
                          />
                        </Button>
                        {formik.values._filename && (
                          <Box display="inline" ml="10px">
                            <IconButton onClick={handleClearSelectedFile}>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                      {!!formik.errors._filename && !!formik.touched._filename && (
                        <Alert severity="error">{formik.errors._filename}</Alert>
                      )}
                    </Box>
                    <Box mt="16px">
                      <Typography color="primary" variant="body1" gutterBottom>
                        <strong>Type</strong>
                      </Typography>
                      <Select
                        name="type"
                        onChange={formik.handleChange}
                        value={formik.values.type}
                        variant="outlined"
                        error={!!formik.touched.type && !!formik.errors.type}
                      >
                        <MenuItem value="Unknown">Maak een keuze</MenuItem>
                        <MenuItem value="Items">Items</MenuItem>
                        <MenuItem value="Locations">Locaties</MenuItem>
                        <MenuItem value="Categories">Categoriëen</MenuItem>
                      </Select>
                    </Box>
                  </>
                ),
              },
              {
                id: 'export-tab',
                component: () => ( // eslint-disable-line
                  <>
                    {error && <Alert severity="error">{error}</Alert>}
                    <Box my="32px">
                      <Typography color="primary" variant="body1" gutterBottom>
                        <strong>Exporteren</strong>
                      </Typography>
                      <Select
                        name="type"
                        onChange={formik.handleChange}
                        variant="outlined"
                        value={formik.values.type}
                        error={!!formik.touched.type && !!formik.errors.type}
                      >
                        <MenuItem value="Unknown">Maak een keuze</MenuItem>
                        <MenuItem value="Items">Items</MenuItem>
                        <MenuItem value="Locations">Locaties</MenuItem>
                        <MenuItem value="Categories">Categoriëen</MenuItem>
                      </Select>
                    </Box>
                  </>
                ),
              },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" disabled={formik.isSubmitting} onClick={onClose} size="large">
            Annuleren
          </Button>
          <Button
            color="secondary"
            disabled={formik.isSubmitting}
            size="large"
            type="submit"
            variant="contained"
            endIcon={formik.isSubmitting ? <CircularProgress size="20px" color="inherit" /> : null}
          >
            Verzenden
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
