import styled from 'styled-components'

export const Projects = styled.main`
  padding: 50px 0;

  > .MuiGrid-container {
    width: 90%;
    margin: 0 auto;
  }
`
