import React, { useState } from 'react'
import { Checkbox } from '@material-ui/core'

import { TableComponent } from '@Interfaces'
import { DeleteIcon } from '@Components/atoms'
import { ConfirmDialog } from '@Components/organisms'
import * as Styled from './styled'

interface IProps extends TableComponent.Row {
  columns: TableComponent.Column[]
  selected: boolean
  innerRef?: any
  deleting?: boolean
}

export const TableRow: React.FC<IProps> = ({
  columns,
  data,
  onSelect,
  onView,
  onDelete,
  innerRef,
  deleting,
  selected,
  ...rest
}: IProps) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)

  const getColumnValue = (column: TableComponent.Column, value: any) => {
    // eslint-disable-line
    if (Array.isArray(value) && column.map) {
      if (value.length === 0 && column.empty) {
        return column.empty()
      }

      return value.map(column.map)
    }

    if (typeof value === 'boolean') {
      if (value) {
        return 'Ja'
      }

      return 'Nee'
    }

    return value || '-'
  }

  const toggleConfirmDialog = (e?: any) => {
    if (e) {
      e.stopPropagation()
    }

    setShowConfirmDialog(!showConfirmDialog)
  }

  const handleDelete = () => {
    toggleConfirmDialog()

    if (!deleting && onDelete) {
      onDelete(data)
    }
  }

  const handleView = () => {
    if (onView) {
      onView(data)
    }
  }

  const stopPropagation = (e: any) => {
    e.stopPropagation()
  }

  return (
    <>
      <Styled.TableRow
        ref={innerRef}
        {...rest}
        viewable={!!onView}
        onClick={() => (onView ? handleView() : undefined)}
      >
        {onSelect && (
          <Styled.Column>
            <Checkbox
              color="primary"
              onClick={stopPropagation}
              onChange={(e: React.ChangeEvent) => onSelect(e, data)}
              checked={selected}
            />
          </Styled.Column>
        )}
        {columns.map((column: TableComponent.Column) => (
          <Styled.Column key={`${column.name}_${JSON.stringify(data[column.name])}`}>
            {getColumnValue(column, data[column.name])}
          </Styled.Column>
        ))}
        <Styled.Actions>
          <Styled.ActionsInner>
            {onDelete && (
              <Styled.DeleteAction onClick={toggleConfirmDialog} deleting={deleting}>
                <DeleteIcon />
              </Styled.DeleteAction>
            )}
          </Styled.ActionsInner>
        </Styled.Actions>
      </Styled.TableRow>
      <Styled.Spacer />
      {onDelete && (
        <ConfirmDialog
          open={showConfirmDialog}
          title="Verwijderen bevestigen"
          body="Weet je zeker dat u dit artikel wilt verwijderen?"
          onCancel={toggleConfirmDialog}
          onConfirm={handleDelete}
        />
      )}
    </>
  )
}
