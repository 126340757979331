import styled, { css, AnyStyledComponent } from 'styled-components'

export const ImagesDrawer: AnyStyledComponent = styled.div`
  ${({ variant }: any) => css`
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0;
    padding-bottom: 12px;
    background-color: white;

    ${variant === 'alt'
      ? css`
          max-height: 410px;
          overflow-y: auto;
        `
      : ''}
  `}
`
