import styled, { css } from 'styled-components'
import { Box as MuiBox } from '@material-ui/core'

export const Box = styled(MuiBox)`
  ${({ theme }: any) => css`
    .MuiSvgIcon-root {
      fill: ${theme.palette.primary.main};
    }
  `}
`
