import React, { useState } from 'react'
import { useFormik, FormikHelpers } from 'formik'
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Tabs } from '@Components/organisms'
import { Photo } from '@Services'

import * as Styled from './styled'

interface IProps {
  photo?: Photo
  open: boolean
  onClose: () => void
  onSubmit: (values: any) => Promise<void>
}

export const AddPhotoDialog = ({ photo, open, onClose, onSubmit }: IProps) => {
  const [error, setError] = useState<string>('')

  const formik = useFormik({
    initialValues: {
      description: photo ? photo.description : '',
      descriptionEN: photo ? photo.descriptionEN : '',
      exportToReport: photo ? photo.exportToReport : true,
      buffer: '',
      mimeType: '',
      _filename: '',
    },
    validate: (values: any) => {
      const errors: { [key: string]: string } = {}

      if (!values.buffer || !values.mimeType) {
        errors._filename = 'Selecteer een foto.'
      }

      return errors
    },
    onSubmit: async (values: any, { setSubmitting, resetForm }: FormikHelpers<any>) => {
      delete values._filename
      setError('')

      try {
        await onSubmit(values)
        resetForm()
        onClose()
      } catch (e) {
        setError(e.message)
      }

      setSubmitting(false)
    },
  })

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files

    if (fileList && fileList.length > 0) {
      const file = fileList[0]
      formik.setFieldValue('_filename', file.name)
      formik.setFieldValue('mimeType', file.type)

      const reader = new FileReader()
      reader.addEventListener('load', (e: any) => {
        formik.setFieldValue('buffer', e.target.result.replace(`data:${file.type};base64,`, ''))
      })
      reader.readAsDataURL(file)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Afbeelding {photo ? 'bewerken' : 'uploaden'}</DialogTitle>
        <DialogContent>
          {error && Object.keys(formik.errors).length > 0 && (
            <Box mb="16px">
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
          <Tabs
            tabs={[
              {
                label: 'Nederlandse omschrijving',
                id: 'edit-translated-content-dialog-tabs-tab-0',
              },
              {
                label: 'Engelse omschrijving',
                id: 'edit-translated-content-dialog-tabs-tab-1',
              },
            ]}
            panels={[
              {
                id: 'edit-translated-content-dialog-tabs-panel-0',
                component: () => ( // eslint-disable-line
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Nederlandse omschrijving"
                    type="text"
                    rows={4}
                    name="description"
                    multiline
                    error={!!formik.touched.description && !!formik.errors.description}
                    helperText={formik.touched.description && formik.errors.description}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                ),
              },
              {
                id: 'edit-translated-content-dialog-tabs-panel-1',
                component: () => ( // eslint-disable-line
                  <TextField
                    autoFocus
                    margin="dense"
                    label="Engelse vertaling"
                    type="text"
                    name="descriptionEN"
                    rows={4}
                    value={formik.values.descriptionEN}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline
                    fullWidth
                  />
                ),
              },
            ]}
          />
          <Box mt="32px">
            <Button variant="contained" component="label">
              {formik.values._filename ? formik.values._filename : 'Kies een foto'}
              <input
                type="file"
                accept=".png, .jpg"
                onChange={handleFileSelect}
                style={{ display: 'none' }}
              />
            </Button>
            {!!formik.touched._filename && !!formik.errors._filename && (
              <Typography color="error" variant="body2">
                {formik.errors._filename}
              </Typography>
            )}
          </Box>
          <Styled.Box mt="16px">
            <FormControlLabel
              label="Fotobijlage"
              control={
                <Checkbox
                  name="exportToReport"
                  checked={formik.values.exportToReport}
                  onChange={formik.handleChange}
                  color="primary"
                />
              }
            />
          </Styled.Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose} disabled={formik.isSubmitting}>
            Annuleren
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={formik.isSubmitting}
            endIcon={formik.isSubmitting ? <CircularProgress color="inherit" size="20px" /> : null}
          >
            Opslaan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
