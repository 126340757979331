import React from 'react'
import { Box, Typography } from '@material-ui/core'

interface IProps {
  name: string
  lines: string[]
  indicated?: boolean
}

export const SidebarItem: React.FC<IProps> = ({ name, lines, indicated }: IProps) => {
  return (
    <Box mb="16px">
      <Typography color="primary" variant="body1">
        <strong>{name}</strong>
      </Typography>
      {lines.map((line: string, i: number) => (
        <Typography key={i} color="primary" variant="body1">
          {indicated && <>&#9702; </>}
          {line}
        </Typography>
      ))}
    </Box>
  )
}
