import React from 'react'
import { Typography } from '@material-ui/core'

import * as Styled from './styled'

interface IProps {
  text: string
}

export const Empty: React.FC<IProps> = ({ text }: IProps) => {
  return (
    <Styled.Empty>
      <Typography variant="body1" component="div">
        {text}
      </Typography>
    </Styled.Empty>
  )
}
