import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { TableComponent } from '@Interfaces'
import { TableHeader, TableRow } from '@Components/molecules'
import * as Styled from './styled'

interface IProps {
  columns: TableComponent.Column[]
  rows: TableComponent.Row[]
  draggable?: boolean
  selectable?: boolean
  onDragEnd?: (result: any) => void
  deletingId?: number
  selected?: number[]
  tableIndex?: number
}

export const Table: React.FC<IProps> = ({
  columns,
  rows,
  draggable,
  onDragEnd,
  deletingId,
  selectable,
  selected,
  tableIndex,
}: IProps) => {
  const renderBody = () => {
    if (!draggable) {
      return (
        <tbody>
          {rows.map((
            row: TableComponent.Row,
            i: number, // eslint-disable-line
          ) => (
            <TableRow
              key={i}
              columns={columns}
              {...row}
              selected={selected ? (selected as any).indexOf(row.data.id) > -1 : false}
            />
          ))}
        </tbody>
      )
    }

    return (
      <DragDropContext onDragEnd={onDragEnd as any}>
        <Droppable droppableId={`droppableTable-${tableIndex}`}>
          {(provided: any) => (
            <tbody ref={provided.innerRef} {...provided.droppableProps}>
              {rows.map((
                row: TableComponent.Row,
                i: number, // eslint-disable-line
              ) => (
                <Draggable key={i} draggableId={`table-${tableIndex}-draggable-${i}`} index={i}>
                  {(provided: any) => (
                    <TableRow
                      innerRef={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      columns={columns}
                      {...row}
                      selected={selected && (selected as any).indexOf(row.data.id) > -1}
                      deleting={deletingId === row.data.id}
                    />
                  )}
                </Draggable>
              ))}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  return (
    <Styled.Table>
      <thead>
        <TableHeader columns={columns} selectable={selectable} />
      </thead>
      {renderBody()}
    </Styled.Table>
  )
}

Table.defaultProps = {
  draggable: false,
  tableIndex: 0,
  onDragEnd: () => {},
}
