import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from '@material-ui/core'

interface IProps {
  open: boolean
  title: string
  body: string
  onConfirm: () => void
  onCancel: (e?: any) => void
}

export const ConfirmDialog: React.FC<IProps> = ({
  open,
  title,
  body,
  onCancel,
  onConfirm,
}: IProps) => {
  return (
    <Dialog open={open} onClose={onCancel} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{body}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>
          Annuleren
        </Button>
        <Button color="primary" onClick={onConfirm} variant="contained">
          Bevestigen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.defaultProps = {
  title: 'Bevestigen',
  body: 'Weet je het zeker?',
}
