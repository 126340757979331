import styled, { css } from 'styled-components'

export const TableRow = styled.tr`
  ${({ theme }) => css`
    padding: 10px;
    text-align: left;
    color: ${theme.palette.primary.main};
    font-weight: 700;

    svg {
      fill: ${theme.palette.primary.main};
    }

    .Mui-checked svg {
      fill: ${theme.palette.secondary.main};
    }
  `}
`
