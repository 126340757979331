import React from 'react'
import { useFormik } from 'formik'
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core'

interface IProps {
  open: boolean
  onClose: () => void
  onSubmit: (values: any) => Promise<void>
}

export const AddLocationDialog = ({ open, onClose, onSubmit }: IProps) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      nameEN: '',
    },
    validate: (values: any) => {
      const errors: { [key: string]: string } = {}

      if (!values.name) {
        errors.name = 'Een naam is verplicht.'
      }

      return errors
    },
    onSubmit: async (values: any) => {
      await onSubmit(values)
      onClose()
    },
  })

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Nieuwe locatie toevoegen</DialogTitle>
        <DialogContent>
          <TextField
            name="name"
            label="Naam"
            onChange={formik.handleChange}
            margin="normal"
            value={formik.values.name}
            helperText={formik.touched.name && formik.errors.name}
            error={!!formik.touched.name && !!formik.errors.name}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
          <TextField
            name="nameEN"
            label="Naam (Engels)"
            onChange={formik.handleChange}
            margin="normal"
            value={formik.values.nameEN}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Annuleren
          </Button>
          <Button
            color="primary"
            variant="contained"
            endIcon={formik.isSubmitting ? <CircularProgress color="inherit" size="20px" /> : null}
            type="submit"
          >
            Opslaan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
