import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  TextField,
  Box,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { NTAB } from '@Interfaces'

interface IProps {
  location: NTAB.Location | null
  open: boolean
  isUpdating: boolean
  isDeleting: boolean
  onClose: () => void
  onUpdate: (values: any) => Promise<void>
  onDelete: () => void
  deleteError: string
}

export const EditLocationDialog = ({
  open,
  location,
  isUpdating,
  isDeleting,
  onClose,
  onUpdate,
  onDelete,
  deleteError,
}: IProps) => {
  const formik = useFormik({
    initialValues: {
      name: location ? location.name : '',
      nameEN: location ? location.nameEN : '',
    },
    validate: (values: any) => {
      const errors: { [key: string]: string } = {}

      if (!values.name) {
        errors.name = 'Een naam is verplicht.'
      }

      return errors
    },
    onSubmit: async (values: any) => {
      await onUpdate(values)
      onClose()
    },
  })

  useEffect(() => {
    if (location) {
      formik.setFieldValue('name', location.name)
      formik.setFieldValue('nameEN', location.nameEN)
    }
  }, [location])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Locatie bewerken</DialogTitle>
        <DialogContent>
          <TextField
            name="name"
            label="Naam"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={!!formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
          <TextField
            name="nameEN"
            label="Naam (Engels)"
            value={formik.values.nameEN}
            onChange={formik.handleChange}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
          <Box height="20px" />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>
            Annuleren
          </Button>
          <Button
            disabled={isDeleting || isUpdating}
            variant="contained"
            color="primary"
            type="submit"
            endIcon={isUpdating ? <CircularProgress size="20px" color="inherit" /> : null}
          >
            Opslaan
          </Button>
        </DialogActions>
        {location && (location.childs === undefined || location.childs.length === 0) && (
          <>
            <Divider />
            <DialogContent>
              <Box py="20px">
                {deleteError && (
                  <Box mb="20px">
                    <Alert severity="error">{deleteError}</Alert>
                  </Box>
                )}
                <Button
                  color="secondary"
                  disabled={isDeleting || isUpdating}
                  style={{ width: '100%' }}
                  endIcon={isDeleting ? <CircularProgress color="inherit" size="20px" /> : null}
                  variant="contained"
                  onClick={onDelete}
                >
                  Verwijder deze locatie
                </Button>
              </Box>
            </DialogContent>
          </>
        )}
      </form>
    </Dialog>
  )
}
