import styled, { css } from 'styled-components'

export const ProjectCard = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.palette.primary.main};
    color: white;
    padding: ${theme.spacing(2)}px;
    width: 100%;
    border-radius: 8px 8px 8px 0;
  `}
`
