import React from 'react'

import * as Styled from './styled'

type TSize = 'small' | 'normal' | 'large'
type TVariant = 'light' | 'dark'

interface IProps {
  size?: TSize
  variant?: TVariant
}

export const Logo: React.FC<IProps> = ({ size, variant }: IProps) => {
  const getHeight = () => {
    if (size === 'small') {
      return '30px;'
    }

    if (size === 'normal') {
      return '50px'
    }

    return 'auto'
  }

  const getWidth = () => {
    if (size === 'large') {
      return '100%'
    }

    return 'auto'
  }

  return (
    <Styled.Logo
      height={getHeight()}
      width={getWidth()}
      src={variant === 'light' ? '/images/logo-white.png' : '/images/logo.png'}
    />
  )
}

Logo.defaultProps = {
  size: 'normal',
  variant: 'dark',
}
