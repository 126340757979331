import React, { useEffect, useState } from 'react'
import find from 'lodash/find'
import { useFormik, FormikHelpers } from 'formik'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { User, ValuationType, Location } from '@Services'
import * as Styled from './styled'

interface IProps {
  open: boolean
  onClose: () => void
  onSubmit: (values: any) => Promise<void>
  users: User[]
  signers: User[]
  locations: Location[]
  valuationTypes: ValuationType[]
}

const mapUserAbbreviations = (users: User[]) => {
  return users.map((u: User) => u.abbreviation).join('.')
}

export const ReportExportDialog = ({
  open,
  onClose,
  onSubmit,
  users,
  signers,
  locations,
  valuationTypes,
}: IProps) => {
  const [error, setError] = useState<string>('')

  const formik = useFormik({
    initialValues: {
      signerUserId: -1,
      valuatorInitials: mapUserAbbreviations(users),
      isAssessmentReport: false,
      isValuationReport: false,
      photoAttachment: false,
      valuationTypes: [''],
      showIndividualValues: false,
      isControlVersion: false,
      language: 'nl',
      locationId: -1,
      _reportType: '',
    },
    validate: async (values: any) => {
      const errors: any = {}

      if (!values.valuatorInitials) {
        errors.valuatorInitials = 'Taxateur initialen mag niet leeg zijn.'
      }

      if (
        values.isValuationReport &&
        values.valuationTypes.length === 1 &&
        values.valuationTypes[0] === ''
      ) {
        errors.valuationTypes = 'Dit veld is verplicht.'
      }

      if (values.valuationTypes.length > 2) {
        errors.valuationTypes = 'U kunt maximaal twee waarden selecteren.'
      }

      if (values.isValuationReport && values.signerUserId === -1) {
        errors.signerUserId = 'Selecteer een ondertekenaar.'
      }

      if (!values.isAssessmentReport && !values.isValuationReport && !values.photoAttachment) {
        errors._reportType = 'Selecteer minstens één optie.'
      }

      return errors
    },
    onSubmit: async (values: any, { setSubmitting, resetForm }: FormikHelpers<any>) => {
      setError('')
      const submitValues = { ...values }

      if (!values.isValuationReport) {
        delete submitValues.signerUserId
        delete submitValues.showIndividualValues
      }

      if (submitValues.locationId === -1) {
        submitValues.locationId = null
      }

      try {
        await onSubmit(submitValues)
        resetForm()
        onClose()
      } catch (e) {
        setError(e.message)
      }

      setSubmitting(false)
    },
  })

  useEffect(() => {
    const { valuationTypes } = formik.values

    if (valuationTypes.indexOf('') > -1 && valuationTypes.length === 2) {
      formik.setFieldValue(
        'valuationTypes',
        formik.values.valuationTypes.filter(v => v !== ''),
      )
    } else if (formik.values.valuationTypes.length === 0) {
      formik.setFieldValue('valuationTypes', [''])
    }
  }, [formik.values.valuationTypes])

  useEffect(() => {
    formik.setFieldValue('valuatorInitials', mapUserAbbreviations(users))
  }, [users])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>Maak een rapport</DialogTitle>
        <DialogContent>
          {error && (
            <Box mb="32px">
              <Alert severity="error">{error}</Alert>
            </Box>
          )}
          <Box mb="32px">
            <Typography color="primary" variant="body1" gutterBottom>
              <strong>Taxateur</strong>
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <TextField
                  label="Initialen"
                  name="valuatorInitials"
                  value={formik.values.valuatorInitials}
                  onChange={formik.handleChange}
                  error={!!formik.touched.valuatorInitials && !!formik.errors.valuatorInitials}
                  helperText={formik.touched.valuatorInitials && formik.errors.valuatorInitials}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  error={!!formik.touched.signerUserId && !!formik.errors.signerUserId}
                  disabled={!formik.values.isValuationReport}
                >
                  <InputLabel>Ondertekenaar</InputLabel>
                  <Select
                    name="signerUserId"
                    value={formik.values.signerUserId}
                    onChange={formik.handleChange}
                    error={!!formik.touched.signerUserId && !!formik.errors.signerUserId}
                  >
                    <MenuItem value={-1}>Maak een keuze</MenuItem>
                    {signers.map((user: User, i: number) => (
                      <MenuItem key={i} value={user.id}>
                        {user.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!formik.touched.signerUserId && !!formik.errors.signerUserId && (
                    <FormHelperText error>{formik.errors.signerUserId}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box mb="32px">
            <Typography color="primary" variant="body1" gutterBottom>
              <strong>Taal</strong>
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  error={!!formik.touched.language && !!formik.errors.language}
                >
                  <InputLabel>Kies een taal</InputLabel>
                  <Select
                    name="language"
                    value={formik.values.language}
                    onChange={formik.handleChange}
                    error={!!formik.touched.language && !!formik.errors.language}
                  >
                    <MenuItem value="nl">Nederlands</MenuItem>
                    <MenuItem value="en">Engels</MenuItem>
                  </Select>
                  {!!formik.touched.language && !!formik.errors.language && (
                    <FormHelperText error>{formik.errors.language}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Styled.Box mb="32px">
            <Typography color="primary" variant="body1" gutterBottom>
              <strong>Welk type rapport wilt u exporteren?</strong>
            </Typography>
            <FormControlLabel
              label="Inventarisatie"
              disabled={formik.values.isValuationReport}
              control={
                <Checkbox
                  name="isAssessmentReport"
                  color="primary"
                  checked={formik.values.isAssessmentReport}
                  onChange={formik.handleChange}
                />
              }
            />
            <FormControlLabel
              label="Taxatie"
              disabled={formik.values.isAssessmentReport}
              control={
                <Checkbox
                  name="isValuationReport"
                  color="primary"
                  checked={formik.values.isValuationReport}
                  onChange={formik.handleChange}
                />
              }
            />
            <FormControlLabel
              label="Fotobijlage"
              control={
                <Checkbox
                  name="photoAttachment"
                  color="primary"
                  checked={formik.values.photoAttachment}
                  onChange={formik.handleChange}
                />
              }
            />

            {!!formik.errors._reportType && !!formik.touched.isValuationReport && (
              <Alert severity="error">{formik.errors._reportType}</Alert>
            )}
          </Styled.Box>
          <Styled.Box mb="32px">
            <Typography color="primary" variant="body1" gutterBottom>
              <strong>Welke waardes wilt u in het rapport?</strong> (max. 2)
            </Typography>
            <Select
              name="valuationTypes"
              disabled={!formik.values.isValuationReport}
              value={formik.values.valuationTypes}
              onChange={formik.handleChange}
              error={!!formik.touched.valuationTypes && !!formik.errors.valuationTypes}
              variant="outlined"
              multiple
            >
              <MenuItem value="">Maak een keuze</MenuItem>
              {valuationTypes.map((valuationType: ValuationType, i: number) => (
                <MenuItem key={i} value={valuationType.id}>
                  {valuationType.name}
                </MenuItem>
              ))}
            </Select>
            {formik.values.valuationTypes.length > 0 && formik.values.valuationTypes[0] !== '' && (
              <Box mt="32px">
                <Typography color="primary" variant="body1" gutterBottom>
                  <strong>Geselecteerd</strong>
                </Typography>
                {formik.values.valuationTypes.map((id: string, i: number) => {
                  const v: ValuationType | undefined = find(valuationTypes, {
                    id: parseInt(id, 10),
                  })

                  if (!v) return null

                  return <Styled.ValuationType key={i}>{v.name}</Styled.ValuationType>
                })}
              </Box>
            )}
          </Styled.Box>
          <Styled.Box mb="32px">
            <FormControlLabel
              label="Individuele waarden tonen"
              control={
                <Checkbox
                  name="showIndividualValues"
                  disabled={!formik.values.isValuationReport}
                  color="primary"
                  checked={formik.values.showIndividualValues}
                  onChange={formik.handleChange}
                />
              }
            />
            <FormControlLabel
              label="Controle versie"
              control={
                <Checkbox
                  name="isControlVersion"
                  color="primary"
                  checked={formik.values.isControlVersion}
                  onChange={formik.handleChange}
                />
              }
            />
          </Styled.Box>
          <Box mb="32px">
            <Typography color="primary" variant="body1" gutterBottom>
              <strong>Welke locatie wilt u exporteren?</strong>
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  error={!!formik.touched.locationId && !!formik.errors.locationId}
                >
                  <Select
                    name="locationId"
                    value={formik.values.locationId}
                    onChange={formik.handleChange}
                    error={!!formik.touched.locationId && !!formik.errors.locationId}
                  >
                    <MenuItem value={-1}>Alle</MenuItem>
                    {locations.map((location: Location, i: number) => (
                      <MenuItem key={i} value={location.id}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {!!formik.touched.locationId && !!formik.errors.locationId && (
                    <FormHelperText error>{formik.errors.locationId}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" disabled={formik.isSubmitting} onClick={onClose} size="large">
            Annuleren
          </Button>
          <Button
            color="secondary"
            disabled={formik.isSubmitting}
            size="large"
            type="submit"
            variant="contained"
            endIcon={formik.isSubmitting ? <CircularProgress size="20px" color="inherit" /> : null}
          >
            Exporteren naar pdf
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
