import styled, { css, AnyStyledComponent } from 'styled-components'

export const LoginTemplate = styled.div`
  display: flex;
`

export const Half: AnyStyledComponent = styled.div`
  height: 100vh;
  width: 50%;
  padding: 100px;

  ${({ theme, image }: any) =>
    !!image &&
    css`
      background-image: url('${image}');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      position: relative;

      &:after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${theme.palette.primary.main};
        opacity: 0.5;
        pointer-events: none;
      }
    `}
`

export const Content = styled.div`
  width: 100%;
  max-width: 380px;
`
