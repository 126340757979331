import styled, { css } from 'styled-components'
import { Box as MuiBox } from '@material-ui/core'

export const Label = styled.label`
  ${({ theme }: any) => css`
    padding-right: 32px;
    cursor: pointer;

    .MuiSvgIcon-root {
      fill: ${theme.palette.primary.main};
    }
  `}
`

export const Box = styled(MuiBox)`
  ${({ theme }: any) => css`
    .MuiSvgIcon-root {
      fill: ${theme.palette.primary.main};
    }

    .MuiOutlinedInput-root {
      max-width: 100%;
    }
  `}
`

export const ValuationType = styled(MuiBox)`
  ${({ theme }: any) => css`
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    background-color: white;
    padding: 10px 12px;
    font-size: 1.1em;
    font-weight: 600;
    color: ${theme.palette.primary.main};
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 8px;
  `}
`
