import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { useSelector } from 'react-redux'
import { Box, CircularProgress, Grid, IconButton, TextField, Typography } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import { Empty } from '@Components/atoms'
import { Table } from '@Components/organisms'
import { TableComponent, NTAB, IStore } from '@Interfaces'
import { getResourceStatus } from '@Redux/resources/selectors'
import { NTABService } from '@Services'
import * as Styled from './styled'

interface IProps {
  projects: NTAB.ProjectsItem[]
  onView: (row: TableComponent.RowData) => void
  onDelete?: (row: TableComponent.RowData) => void
  onSelect?: (row: TableComponent.RowData) => void
}

export const Projects: React.FC<IProps> = ({ projects, onSelect, onView, onDelete }: IProps) => {
  const [currentProjects, setCurrentProjects] = useState(projects)
  const isReading = useSelector((state: IStore) =>
    getResourceStatus('projects', 'isReading', state.resources),
  )

  const searchForm = useFormik({
    initialValues: {
      name: '',
      projectNumber: '',
    },
    validate: (values: any) => {
      if (!values.name && !values.projectNumber) {
        return {
          name: 'Vul een van de twee velden in om te zoeken.',
          projectNumber: 'Vul een van de twee velden in om te zoeken.',
        }
      }

      return {}
    },
    onSubmit: async (values: any, { setSubmitting }) => {
      const response = await NTABService.SearchProject(values.projectNumber, values.name)

      if (response.success) {
        setCurrentProjects(response.data)
      }

      setSubmitting(false)
    },
  })

  const columns: TableComponent.Column[] = [
    {
      name: 'valuationDate',
      displayName: 'Taxatiedatum',
    },
    {
      name: 'projectNumber',
      displayName: 'Projectnummer',
    },
    {
      name: 'name',
      displayName: 'Naam',
    },
  ]

  const rows: TableComponent.Row[] = currentProjects.map((project: NTAB.ProjectsItem) => ({
    data: project,
    onSelect,
    onView,
    onDelete,
  }))

  useEffect(() => {
    setCurrentProjects(projects)
  }, [projects])

  useEffect(() => {
    const { name, projectNumber } = searchForm.values

    if (!name && !projectNumber) {
      setCurrentProjects(projects)
    }
  }, [searchForm.values])

  return (
    <Styled.Projects>
      <Grid container>
        <Grid item xs={12}>
          <Typography component="h1" variant="h4" gutterBottom>
            Mijn projecten
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3} component="form" onSubmit={searchForm.handleSubmit}>
        <Grid item xs={12} md={5} lg={3}>
          <TextField
            name="projectNumber"
            label="Project nummer"
            onChange={searchForm.handleChange}
            value={searchForm.values.projectNumber}
            disabled={searchForm.isSubmitting}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            name="name"
            label="Naam"
            onChange={searchForm.handleChange}
            value={searchForm.values.name}
            disabled={searchForm.isSubmitting}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <IconButton onClick={searchForm.submitForm} disabled={searchForm.isSubmitting}>
            <SearchIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          {rows.length > 0 ? (
            <Table columns={columns} rows={rows} />
          ) : isReading ? (
            <Box textAlign="center" padding="32px">
              <CircularProgress />
            </Box>
          ) : (
            <Empty text="Er zijn geen projecten om te tonen." />
          )}
        </Grid>
      </Grid>
    </Styled.Projects>
  )
}
