import styled, { css, AnyStyledComponent } from 'styled-components'

export const Sidebar = styled.aside`
  height: 100vh;
  width: 388px;
  background-color: white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  padding: 32px;
  overflow: hidden;
  position: relative;
  z-index: 1;
`

export const Clickable: AnyStyledComponent = styled.div`

  z-index: 200;
  cursor: pointer;
  opacity: 0;
  pointer-events: all;
  position: absolute;
  border-radius: 50px;
  width: 23px;
  height: 23px;
  right: 0;
    
  &:hover {
    opacity: 1;
  }
`

export const Li: AnyStyledComponent = styled.li`
${({ theme, active }: any) => css`

    position: relative;
    margin: -5px 0 0 -1px;
    padding: 5px 20px 0 60px;
    list-style: none;

    &:after {
      background-color: ${active
        ? theme.palette.secondary.main
        : theme.palette.primary.main} !important;
      content: '\\e145';
      font-family: 'Material Icons';
      text-align: right;
      font-weight: 700;
      position: absolute;
      border-radius: 50px;
      width: 23px;
      height: 23px;
      padding-right: 4.5px;
      color: white;
      right: 0;
      z-index: -1;
      padding-top: 1px;
    `}
  }
`