import React from 'react'
import Head from 'next/head'
import { Box, Grid, Typography } from '@material-ui/core'

import { Header } from '@Components/organisms'
import * as Styled from './styled'

interface IProps {
  pageTitle: string
  message: string
}

export const ErrorTemplate: React.FC<IProps> = ({ pageTitle, message }: IProps) => {
  return (
    <Styled.ErrorTemplate>
      <Head>
        <title>{pageTitle} | NTAB Portal</title>
      </Head>
      <Header />
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" justifyContent="center" height="300px">
            <Typography variant="h5" color="primary">
              {message}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Styled.ErrorTemplate>
  )
}
