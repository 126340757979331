import React from 'react'
import { Typography } from '@material-ui/core'

import * as Styled from './styled'

interface IProps {
  text: string
  onClick: () => void
}

export const TabText: React.FC<IProps> = ({ text, onClick }: IProps) => {
  if (text === '' || text === '<p><br></p>') {
    return (
      <Styled.Div onClick={onClick}>
        <Typography variant="body1">
          <i>Geen omschrijving.</i>
        </Typography>
      </Styled.Div>
    )
  }

  return <Styled.Div onClick={onClick} dangerouslySetInnerHTML={{ __html: text }} />
}
