import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import
  {
    Box,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    FormControlLabel,
    Button,
    Checkbox,
    CircularProgress
  } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ConfirmDialog } from '@Components/organisms'
import { NTABService, ValuationType } from '@Services'
import { IHttp } from '@Interfaces'
import * as Styled from './styled'

interface IProps
{
  open: boolean
  projectId: number
  valuationTypes: ValuationType[]
  onClose: () => void
}

export const CreateValuationTypeDialog = ({ open, onClose, projectId, valuationTypes }: IProps) =>
{
  const [error, setError] = useState<string>('')
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)

  const toggleConfirmDialog = (e?: any) => {
    if (e) {
      e.stopPropagation()
    }

    setShowConfirmDialog(!showConfirmDialog)
  }

  const formik = useFormik({
    initialValues: {
      id: -1,
      selectedValuationTypes: [],
      allValuationTypes: [],
      _selectedValuationTypes: []
    },
    
    onSubmit: async (_values: any, { setSubmitting, resetForm }) =>
    {
      setError('')
      if (projectId && formik.values._selectedValuationTypes.length > 0 )
      {
        let _selectedValuationTypesId: number[] = []
        formik.values._selectedValuationTypes.forEach((sv: any) => (
          _selectedValuationTypesId.push(sv.id)
        ))

        const projectResult: IHttp.Response = await NTABService.UpdateProjectValuationTypes(
          projectId,
          _selectedValuationTypesId,
        )

        if (projectResult.success === false) {
          alert(projectResult.error)
          setError(projectResult.error || 'Er ging iets fout, probeer het opnieuw.')
          return
        } else {
          formik.values._selectedValuationTypes.forEach((sv: any) => (
            formik.values.selectedValuationTypes.push(sv)
          ))
          toggleConfirmDialog()
          resetForm()
          onClose()
        }
      }

      setSubmitting(false)
    }
  })

  useEffect(() =>
  {
    formik.values.projectId = projectId;
    formik.values.selectedValuationTypes = valuationTypes

    if (formik.values.allValuationTypes.length === 0) 
    {
      formik.values._selectedValuationTypes = []
      ; (async () =>
      {
        const result: IHttp.Response = await NTABService.ReadResource('valuation-types')
        if (result.success) {
          formik.values.allValuationTypes = result.data
        } else {
          setError(result.error || 'Er ging iets fout, probeer het opnieuw.')
        }
      })()
    }
  }, [formik.values.allValuationTypes])

  const updateShownValuationTypes = async () => {
    if (formik.values.selectedValuationTypes.length !== 0 && formik.values.allValuationTypes.length !== 0) {
      let array = JSON.parse(JSON.stringify(formik.values.allValuationTypes));

      formik.values.selectedValuationTypes.forEach((v: any) => (
        array = array.filter((item: any) => item.id !== v.id)
      ))

      formik.values.allValuationTypes = array
    }
  }

  const handleValuationTypes = async () => {
    await updateShownValuationTypes()
  }

  const handleCheckboxSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.checked === true) {
      formik.values.allValuationTypes.forEach((av: any) => (
        av.abbreviation === e.target.name ? 
        formik.values._selectedValuationTypes.push(av)
        : ""
      ))
    } else if (e.target && e.target.checked === false) {
      let array = formik.values._selectedValuationTypes
      formik.values._selectedValuationTypes.forEach((sv: any) => (
        sv.abbreviation === e.target.name ? 
        array.splice(formik.values._selectedValuationTypes.indexOf(sv), 1)
        : ""
      ))

      formik.values._selectedValuationTypes = array
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Waardering toevoegen</DialogTitle>
      <DialogContent>
        {error && (
          <Box mb="20px">
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        {formik.values.allValuationTypes && handleValuationTypes() &&
          formik.values.allValuationTypes.map((av: any) => (
            <Styled.TableRow>
              <FormControlLabel
                  control={
                    <Checkbox
                      name={av.abbreviation}
                      onChange={handleCheckboxSelect}
                      color="secondary"
                    />
                  }
                  label={av.name}
                />
            </Styled.TableRow>
          ))}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose} disabled={formik.isSubmitting}>
          Annuleren
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={formik.isSubmitting}
          endIcon={formik.isSubmitting ? <CircularProgress size="20px" color="inherit" /> : null}
          onClick={toggleConfirmDialog}
        >
          Opslaan
        </Button>
      </DialogActions>
      <ConfirmDialog
        open={showConfirmDialog}
        title="Waardering toevoegen bevestigen"
        body="U staat op het punt om waardering(en) toe te voegen. Deze actie kan niet ongedaan gemaakt worden."
        onCancel={toggleConfirmDialog}
        onConfirm={formik.handleSubmit}
      />
    </Dialog>
  )
}