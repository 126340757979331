import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { Paper } from '@material-ui/core'

import { NTAB } from '@Interfaces'
import { DraggableLocation } from './DraggableLocation'

interface DroppableLocationProps {
  projectId: number
  location: NTAB.Location
  index: number
  indentLevel: number
}

export const DroppableLocation: React.FC<DroppableLocationProps> = ({
  projectId,
  location,
  index,
  indentLevel,
}: DroppableLocationProps) => {
  const renderDraggable = (provided: any) => {
    return (
      <>
        <DraggableLocation
          projectId={projectId}
          location={location}
          index={index}
          indentLevel={indentLevel}
        />
        {provided.placeholder}
      </>
    )
  }

  const renderDroppable = () => {
    return (
      <Droppable droppableId={`sort_location_droppable_${location.id}`}>
        {(provided: any) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ paddingLeft: 30 * indentLevel }}
            >
              {renderDraggable(provided)}
            </div>
          )
        }}
      </Droppable>
    )
  }

  if (indentLevel === 0) {
    return <Paper>{renderDroppable()}</Paper>
  }

  if (indentLevel === 1) {
    return renderDroppable()
  }

  return (
    <DraggableLocation
      projectId={projectId}
      location={location}
      index={index}
      indentLevel={indentLevel}
    />
  )
}
