import React from 'react'
import { Box, Button, Grid } from '@material-ui/core'

import { Photo } from '@Services'
import { PhotoCard } from '@Components/molecules'
import * as Styled from './styled'

interface IProps {
  items: Photo[]
  onEdit: (values: any, index: number, photo: Photo) => void
  onDelete: (index: number, photo: Photo) => void
  onButtonClick?: () => void
  projectId?: number
  variant?: 'normal' | 'alt'
}

export const Photos: React.FC<IProps> = ({
  projectId,
  items,
  variant,
  onEdit,
  onDelete,
  onButtonClick,
}: IProps) => {
  return (
    <Styled.ImagesDrawer variant={variant}>
      <Grid container alignItems="center">
        {variant === 'alt' && (
          <Grid item xs={12}>
            {onButtonClick && (
              <Box display="flex" justifyContent="flex-start">
                <Button variant="contained" component="label" onClick={onButtonClick}>
                  Foto toevoegen
                </Button>
              </Box>
            )}
          </Grid>
        )}
      </Grid>
      <Box height="20px" />
      <Grid container>
        <Grid item xs={12}>
          {items.length > 0 &&
            items.map((photo: Photo, i: number) => (
              <PhotoCard
                key={i}
                projectId={projectId}
                photo={photo}
                onEdit={(values: any, photo: Photo) => onEdit(values, i, photo)}
                onDelete={(photo: Photo) => onDelete(i, photo)}
              />
            ))}
        </Grid>
      </Grid>
    </Styled.ImagesDrawer>
  )
}

Photos.defaultProps = {
  variant: 'normal',
}
