import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { HeaderComponent } from '@Interfaces'
import * as Styled from './styled'

export const HeaderItem: React.FC<HeaderComponent.Item> = ({
  // icon,
  title,
  href,
  as,
  onClick,
}: HeaderComponent.Item) => {
  const router = useRouter()
  let isActive = router.pathname === href

  // must be a better way for this bs
  if (
    (router.pathname === '/projects/[projectId]/locations/[locationId]' ||
      router.pathname === '/projects/[projectId]/locations/sort' ||
      router.pathname === '/projects/[projectId]/locations/[locationId]/articles' ||
      router.pathname === '/projects/[projectId]/locations/[locationId]/articles/[articleId]') &&
    href === '/projects/[projectId]/locations'
  ) {
    isActive = true
  } else if (
    (router.pathname === '/projects/[projectId]/users/[userId]' ||
      router.pathname === '/projects/[projectId]/users/new') &&
    href === '/projects/[projectId]/users'
  ) {
    isActive = true
  } else if (
    router.pathname === '/projects/[projectId]/photos' &&
    href === '/projects/[projectId]/photos'
  ) {
    isActive = true
  }

  // const renderIcon = () => {
  //   if (!icon) return null

  //   return <Styled.Icon>{icon}</Styled.Icon>
  // }

  if (onClick) {
    return (
      <Styled.HeaderItem onClick={onClick} isActive={isActive}>
        <Styled.Title>{title}</Styled.Title>
      </Styled.HeaderItem>
    )
  }

  if (as) {
    return (
      <Styled.HeaderItem isActive={isActive}>
        <Link href={href} as={as} passHref>
          <Styled.Link>
            <Styled.Title>{title}</Styled.Title>
          </Styled.Link>
        </Link>
      </Styled.HeaderItem>
    )
  }

  return (
    <Styled.HeaderItem isActive={isActive}>
      <Styled.Title>{title}</Styled.Title>
    </Styled.HeaderItem>
  )
}
