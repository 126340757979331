/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useFormik, FormikHelpers } from 'formik'
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Tabs } from '@Components/organisms'
import SunEditor from 'suneditor-react'

const rteOptions = {
  height: 200,
  buttonList: [['bold', 'underline', 'italic'], ['list'], ['table'], ['fullScreen', 'codeView']],
}

interface IProps {
  dialog: string
  title: string
  values: string[]
  open: boolean
  useRte: boolean
  onClose: () => void
  onSubmit: (dialog: string, values: any) => Promise<void>
}

export const EditTranslatedContentDialog = ({
  dialog,
  title,
  values,
  open,
  useRte,
  onClose,
  onSubmit,
}: IProps) => {
  const [error, setError] = useState<string>('')

  const formik = useFormik({
    initialValues: {
      nl: values[0],
      en: values[1],
    },
    onSubmit: async (_values: any, { setSubmitting, resetForm }: FormikHelpers<any>) => {
      try {
        await onSubmit(dialog, _values)
        resetForm()
        onClose()
      } catch (e) {
        setError(e.error)
      }

      setSubmitting(false)
    },
  })

  useEffect(() => {
    formik.resetForm()
  }, [])

  useEffect(() => {
    formik.setFieldValue('nl', values[0])
    formik.setFieldValue('en', values[1])
  }, [values])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <Tabs
            tabs={[
              {
                label: 'Nederlands',
                id: 'edit-translated-content-dialog-tabs-tab-0',
              },
              {
                label: 'Engels',
                id: 'edit-translated-content-dialog-tabs-tab-1',
              },
            ]}
            panels={[
              {
                id: 'edit-translated-content-dialog-tabs-panel-0',
                component: () => { // eslint-disable-line
                  if (useRte) {
                    return (
                      <SunEditor
                        name="nl"
                        onChange={(content: string) => formik.setFieldValue('nl', content)}
                        setOptions={rteOptions as any}
                        setContents={formik.values.nl || ''}
                      />
                    )
                  }

                  return (
                    <TextField
                      autoFocus
                      margin="dense"
                      label="Nederlandse vertaling"
                      type="text"
                      rows={4}
                      name="nl"
                      multiline
                      value={formik.values.nl}
                      onChange={formik.handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                    />
                  )
                },
              },
              {
                id: 'edit-translated-content-dialog-tabs-panel-1',
                component: () => { // eslint-disable-line
                  if (useRte) {
                    return (
                      <SunEditor
                        name="en"
                        onChange={(content: string) => formik.setFieldValue('en', content)}
                        setOptions={rteOptions as any}
                        setContents={formik.values.en || ''}
                      />
                    )
                  }

                  return (
                    <TextField
                      autoFocus
                      margin="dense"
                      label="Engelse vertaling"
                      type="text"
                      name="en"
                      rows={4}
                      value={formik.values.en}
                      onChange={formik.handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      multiline
                      fullWidth
                    />
                  )
                },
              },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose} disabled={formik.isSubmitting}>
            Annuleren
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={formik.isSubmitting}
            endIcon={formik.isSubmitting ? <CircularProgress size="20px" color="inherit" /> : null}
          >
            Opslaan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

EditTranslatedContentDialog.defaultProps = {
  useRte: false,
}
