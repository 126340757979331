import React, { ReactElement, useEffect } from 'react'
import { useStore, useSelector, useDispatch } from 'react-redux'
import { AzureAD, AuthenticationState } from 'react-aad-msal'

import { authProvider } from '@Providers'
import { LoginTemplate } from '@Components/templates'
import { IStore } from '@Interfaces'
import { getCurrentProject, getIsAuthenticated } from '@Redux/runtime/selectors'
import { getResourceError } from '@Redux/resources/selectors'
import { RuntimeActions } from '@Redux/actions'

export const AuthTemplate: React.FC = ({ children }) => {
  const store = useStore()
  const dispatch = useDispatch()
  const currentProject = useSelector((store: IStore) => getCurrentProject(store.runtime))
  const isAuthenticated = useSelector((store: IStore) => getIsAuthenticated(store.runtime))
  const projectsError = useSelector((store: IStore) =>
    getResourceError('projects', store.resources),
  )

  useEffect(() => {
    if (
      (currentProject.error === 'Unauthorized' || projectsError === 'Unauthorized') &&
      isAuthenticated
    ) {
      dispatch(RuntimeActions.RefreshToken())
    }
  }, [currentProject.error, isAuthenticated, projectsError])

  return (
    <AzureAD provider={authProvider} reduxStore={store} forceLogin={false}>
      {({ login, logout, authenticationState }: any) => {
        switch (authenticationState) {
          case AuthenticationState.Authenticated:
            return React.cloneElement(children as ReactElement, { logout })
          case AuthenticationState.Unauthenticated:
          case AuthenticationState.InProgress:
            return <LoginTemplate login={login} />
          default:
            return <div>{authenticationState}</div>
        }
      }}
    </AzureAD>
  )
}
